import { gql, useQuery } from '@apollo/client';
import { DynamicImage, Slider } from '@common';
import Link from 'next/link';

export const GET_CMS_BLOCKS = gql`
  query cmsBlocks($identifiers: [String]!) {
    cmsBlocks(identifiers: $identifiers) {
      items {
        content
        identifier
      }
      block_content {
        content
        url
        image
        alt_image
      }
    }
  }
`;

const MAX_SLIDE_COUNT = 4;

export const NewsBanner = () => {
  const { data } = useQuery(GET_CMS_BLOCKS, {
    variables: { identifiers: 'news-banner' }
  });

  return (
    <section className="p-2 mb-4 md:p-0 gtm-principaisProdutos">
      <div className="hidden lg:grid grid-cols-4 gap-6 container mx-auto">
        {data?.cmsBlocks?.block_content
          ?.slice(0, MAX_SLIDE_COUNT)
          .map(({ url, image, alt_image }) => (
            <Link
              href={url}
              key={image}
              prefetch={false}
              className="w-full col-span-2 md:col-span-1 relative rounded overflow-hidden"
            >
              <DynamicImage
                desktopSrc={image}
                mobileSrc={image}
                desktopProps={{
                  width: 324,
                  height: 432,
                  quality: 60
                }}
                alt={alt_image}
                data-imgmidia={image.match(/https?:\/\/[^/]+(.+)/)[1]}
              />
            </Link>
          ))}
      </div>

      <div className="lg:hidden w-full mb-6">
        {data?.cmsBlocks?.block_content?.length > 0 && (
          <Slider
            autoPlaySpeed={9000}
            pauseOnHover
            renderDotsOutside={true}
            itemClass="w-auto"
            arrows={false}
            partialVisible
          >
            {data?.cmsBlocks?.block_content?.map(
              ({ url, image, alt_image }) => (
                <Link
                  href={url}
                  key={image}
                  prefetch={false}
                  className="w-[68vw] col-span-2 md:col-span-1 relative rounded overflow-hidden min-h-[91vw] block mr-4 mb-1"
                >
                  <DynamicImage
                    mobileSrc={image}
                    desktopSrc={image}
                    mobileProps={{
                      width: 280,
                      height: 375,
                      quality: 60
                    }}
                    alt={alt_image}
                  />
                </Link>
              )
            )}
          </Slider>
        )}
      </div>
    </section>
  );
};
