import { FC, ReactNode } from 'react';
import { useAuth } from '@hooks';
import Link, { LinkProps } from 'next/link';

export interface SignedInLinkProps extends LinkProps {
  children: ReactNode;
  className?: string;
}

export const SignedInLink: FC<SignedInLinkProps> = ({
  href,
  ...props
}: SignedInLinkProps) => {
  const { isSignedIn } = useAuth();
  const url = isSignedIn ? href : `/sign-in?redirect=${href}`;
  return <Link {...props} href={url} />;
};
