import { TierPriceBuy } from './TierPriceBuy';
import Star from '../../../../public/assets/icons/star.svg';
import { FlavorsTag } from './FlavorsTag';
import Link from 'next/link';
import { SingleProduct } from 'src/hooks/useProductPage';
import { ProductPrice } from './ProductPrice';
import { useCountFlavorQuantity } from '@hooks';
import { getProductLink } from '@utils';
import classNames from 'classnames';
import he from 'he';
import { Tags } from './Tags';
import Image from 'next/image';
import BuyButton from './BuyButton';

export interface ProductCardProps {
  product: SingleProduct;
  className?: string;
  narrow?: boolean;
  canBuy?: boolean;
  setIsOpen?: (val: boolean) => void;
  onClick?: () => void;
}

export const ProductCard = ({
  product,
  className,
  setIsOpen,
  onClick,
  canBuy = true,
  narrow = false
}: ProductCardProps) => {
  const uri = getProductLink(product);
  const flavorQuantity = useCountFlavorQuantity(product.variants);
  return (
    <div
      className={classNames(
        'shadow-lg rounded p-3 pb-4 hover:border-tertiary-medium flex bg-white justify-start items-start overflow-hidden flex-col ',
        className
      )}
    >
      <Link
        href={uri}
        prefetch={false}
        onClick={() => {
          setIsOpen && setIsOpen(false);
          onClick && onClick();
        }}
      >
        <div className="flex w-full flex-col">
          <div className="mr-4 lg:mr-0 lg:w-full flex my-auto lg:my-0 items-center justify-center relative h-full flex-col">
            <div className="min-h-7 text-center">
              {product.price_range.minimum_price.discount &&
                product.price_range.minimum_price.discount.percent_off > 0 && (
                  <div
                    className={`bg-success-lightest text-success-dark px-2 mb-2 rounded-sm flex items-center justify-center`}
                  >
                    <span className="text-xs text-center font-bold uppercase">
                      {parseInt(
                        String(
                          product.price_range.minimum_price.discount.percent_off.toFixed(
                            0
                          )
                        )
                      )}
                      % na unidade
                    </span>
                  </div>
                )}

              {product.price_tiers &&
                product.price_tiers[0] &&
                product.price_range.minimum_price.discount &&
                !product.price_range.minimum_price.discount.percent_off && (
                  <div
                    className={`bg-primary-lightest text-primary-medium px-2 mb-2 rounded-sm flex items-center justify-center`}
                  >
                    <span className="text-xs text-center font-bold uppercase">
                      Compre + pague -
                    </span>
                  </div>
                )}
            </div>

            <div className="lg:mb-4 w-full md:max-h-[136px] h-[120px] lg:mx-auto relative">
              <Image
                alt={product.name}
                src={product.small_image.url}
                className="object-scale-down"
                priority={false}
                fill
              />
              <Tags narrow={narrow} tags={product.tags} />
            </div>
          </div>

          <div className="w-full mt-3">
            <h3
              className={classNames(
                'text-left font-bold text-gray-darkest line-clamp-2 lg:mt-0',
                { 'text-sm': narrow }
              )}
            >
              {product.name}
            </h3>

            <div className="flex flex-row items-center justify-between w-full min-h-[30px]  mt-1 mb-3 pt-1">
              <div className="flex items-center">
                <p className="text-sm text-gray-darkest mr-2">
                  {((product.rating_summary * 5) / 100).toFixed(1)}
                </p>
                <Star
                  className={
                    (product.rating_summary * 5) / 100 > 0
                      ? 'text-brand-yellow-medium'
                      : 'text-gray-light'
                  }
                />
              </div>
              <FlavorsTag
                className="w-[max-content] mt-lg-4 flex py-1 text-gray-darkest"
                quantity={flavorQuantity}
              />
            </div>
            <div className="min-h-[64px] mt-4 flex">
              <div
                dangerouslySetInnerHTML={{
                  __html: he.decode(product?.short_description?.html)
                }}
                className={classNames(
                  'trim text-sm text-gray-dark text-left line-clamp-3 md:line-clamp-3 [&>p:first-child]:line-clamp-3 md:[&>p:first-child]:line-clamp-3',
                  { 'text-xs': narrow }
                )}
              />
            </div>

            <div className="mt-4 flex flex-col gap-y-2 text-xs md:text-sm">
              <ProductPrice
                priceClassName="text-lg lg:text-xl"
                price={product.price_range.minimum_price}
              />
              {product.price_tiers && product.price_tiers[0] && (
                <TierPriceBuy
                  className="mb-0"
                  valueDiscount={
                    product.price_tiers[0].discount &&
                    product.price_tiers[0].discount.percent_off
                  }
                  quantity={product.price_tiers[0].quantity}
                  value={
                    product.price_tiers[0].final_price &&
                    product.price_tiers[0].final_price.value
                  }
                  size="large"
                />
              )}
            </div>
          </div>
        </div>
      </Link>

      {canBuy && <BuyButton product={product} />}
    </div>
  );
};
