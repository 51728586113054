import { useMemo } from 'react';

export const useCountFlavorQuantity = variants => {
  return useMemo(() => {
    if (variants && typeof variants !== 'undefined') {
      return variants.reduce((accumulator, variant) => {
        if (variant.product.custom_flavor) {
          accumulator++;
        }

        return accumulator;
      }, 0);
    }

    return 1;
  }, [variants]);
};
