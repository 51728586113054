interface PriceProps {
  value: number;
  currencyCode: string;
}

export const Price = ({ value, currencyCode }: PriceProps) => {
  const formattedValue = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: currencyCode
  }).format(value);

  return <span>{formattedValue}</span>;
};
