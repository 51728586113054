import { ReactNode } from 'react';

export interface ProgressBarProps {
  current: number;
  target: number;
  color: 'secondary' | 'success';
  children?: ReactNode;
}

export function ProgressBar({
  current,
  target,
  color,
  children
}: ProgressBarProps) {
  const getProgress = (from: number, to: number) =>
    Math.min(1, from / to) * 100;

  const getWidthClass = (progress: number) => {
    if (progress >= 100) {
      return 'w-full rounded-full';
    }

    if (progress >= 60) {
      return 'w-4/5';
    }

    if (progress >= 40) {
      return 'w-3/5';
    }

    if (progress >= 20) {
      return 'w-2/5';
    }

    if (progress > 0) {
      return 'w-1/5';
    }

    return 'w-0';
  };

  const widthClass = getWidthClass(getProgress(current, target));
  const colorClass = `${color === 'secondary' ? `bg-secondary-light` : `bg-success-medium`}`;
  return (
    <div className="mb-4 flex flex-col">
      <div className="rounded-full w-fulll h-2.5 bg-gray-light">
        <div
          className={`transition-[width] rounded-l-full ${widthClass} h-2.5 ${colorClass}`}
        ></div>
      </div>
      {children}
    </div>
  );
}
