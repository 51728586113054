import classNames from 'classnames';

interface ErrorMessageProps {
  error: string | undefined;
  className?: string;
}

export const ErrorMessage = ({ error, className }: ErrorMessageProps) => {
  return error ? (
    <p
      className={classNames(
        'pt-2 font-light text-sm text-error-medium',
        className
      )}
    >
      {error}
    </p>
  ) : null;
};
