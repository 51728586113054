import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';

export const GET_RECURRENCES_BY_ID = gql`
  query getRecurrenceById($id: String) {
    getRecurrenceById(id: $id) {
      id
      cycle
      cycle_value
      status
      day_date_action
      created_at
      address
      address_id
      billing_id
      next_date_order
      qty_items
      shipping_method
      last_card_number
      token_card_id
      items {
        img
        name
        qty
      }
      orders {
        id
        status
        value
        created_at
        created_at_formated
      }
    }
  }
`;

export const LIST_RECURRENCES = gql`
  query listRecurrence {
    listRecurrence {
      id
      status
      status_last_order
      next_date_order
      value_last_order
      is_payment_method_available
      items {
        img
        name
        qty
        __typename
      }
      __typename
    }
  }
`;

export const UPDATE_RECURRENCES = gql`
  mutation updateRecurrence(
    $cycle: String
    $id: String
    $date_action: String
    $status: String
    $token_id: String
    $address_id: String
    $billing_id: String
  ) {
    updateRecurrence(
      cycle: $cycle
      id: $id
      date_action: $date_action
      status: $status
      token_id: $token_id
      address_id: $address_id
      billing_id: $billing_id
    ) {
      token_id
      cycle
      address_id
      billing_id
      status
      products
      date_action
    }
  }
`;

export const UPDATE_TOKEN_CARD = gql`
  mutation updateTokenCard(
    $id: String
    $token: String
    $card_brand: String
    $final_number: String
    $gateway: String
    $fullname_card: String
    $status: String
  ) {
    updateTokenCard(
      id: $id
      token: $token
      card_brand: $card_brand
      final_number: $final_number
      gateway: $gateway
      fullname_card: $fullname_card
      status: $status
    ) {
      token
      card_brand
      final_number
      gateway
      fullname_card
      status
    }
  }
`;

export const useRecurrenceList = ({ isSignedIn }) => {
  const response = useQuery(LIST_RECURRENCES, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
    skip: !isSignedIn
  });

  return response;
};
