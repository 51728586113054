import classNames from 'classnames';
import { Dispatch, SetStateAction } from 'react';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import ArrowLeft from '@assets/icons/arrow-left.svg';
import ArrowRight from '@assets/icons/arrow-right.svg';
import { usePathname } from 'next/navigation';

interface PaginationProps extends Omit<ReactPaginateProps, 'onPageChange'> {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  onPageChange: (page: number) => void;
  className?: string;
}

export const Pagination = ({
  page,
  setPage,
  onPageChange,
  className,
  ...props
}: PaginationProps) => {
  const pathname = usePathname();
  return (
    <ReactPaginate
      {...props}
      className={classNames(
        'flex text-gray-dark text-sm rounded overflow-hidden',
        className
      )}
      breakClassName="hidden"
      pageLinkClassName="w-[40px] h-[40px] flex items-center justify-center font-bold border border-collapse -ml-[1px] border-gray-dark"
      nextLinkClassName={classNames(
        'flex items-center justify-center font-bold w-[40px] lg:w-[72px] border border-l-0 rounded-r h-full',
        {
          hidden: page === props.pageCount
        }
      )}
      previousLinkClassName={classNames(
        'flex items-center justify-center font-bold w-[40px] lg:w-[72px] border border-r-0 rounded-l h-full',
        {
          hidden: page === 1
        }
      )}
      activeLinkClassName="bg-primary-medium text-white border-0"
      previousLabel={
        <>
          <span className="hidden lg:inline">Anterior</span>
          <span className="lg:hidden">
            <ArrowLeft width={8} />
          </span>
        </>
      }
      nextLabel={
        <>
          <span className="hidden lg:inline">Próximo</span>
          <span className="lg:hidden">
            <ArrowRight width={8} />
          </span>
        </>
      }
      pageRangeDisplayed={page === 1 ? 5 : 4}
      marginPagesDisplayed={0}
      forcePage={page - 1}
      hrefAllControls
      hrefBuilder={pageIndex =>
        pageIndex === 1 ? pathname : `${pathname}?page=${pageIndex}`
      }
      onPageChange={({ selected }) => {
        const selectedPage = selected + 1;
        setPage(selectedPage);
        onPageChange(selectedPage);
      }}
      renderOnZeroPageCount={() => null}
    />
  );
};
