import Logo from '@assets/icons/signature_logo.svg';
import TooltipWhiteIcon from '@assets/icons/tooltip_white.svg';
import SubscriptionCheckboxChecked from '@assets/icons/subscription_checkbox_checked.svg';
import SubscriptionDisclaimerText from '../SubscriptionDisclaimerText';
import Tooltip from '../Tooltip';
import SubscriptionTooltipContent from '../SubscriptionTooltipContent';

const SubscriptionMinibanner = ({
  onCLickSignature = () => {},
  isCheckout = false
}) => {
  return (
    <div
      onClick={onCLickSignature}
      className={`cursor-pointer p-4 mb-2 border rounded bg-primary-dark border-primary-dark w-full lg:mt-0 ${isCheckout ? '' : 'lg:ml-4'}  h-fit`}
    >
      <div className="flex gap-2">
        {isCheckout ? (
          <Logo className="keep-color" />
        ) : (
          <SubscriptionCheckboxChecked className="keep-color" />
        )}

        <p className="inline lh-4 max-w-[200px] wrap text-white font-bold">
          Você está programando essa compra{' '}
          <Tooltip icon={<TooltipWhiteIcon className="keep-color" />}>
            <SubscriptionTooltipContent />
          </Tooltip>
        </p>
      </div>
      <SubscriptionDisclaimerText color="text-white" />
    </div>
  );
};

export default SubscriptionMinibanner;
