import {
  createContext,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from 'react';
import { Persistence } from '@utils';

export interface iStore {
  storeCode: string;
  handleSetStore(payload: string): void;
}

export const StoreContext = createContext({} as iStore);

export const StoreProvider = memo(({ children }: { children: ReactNode }) => {
  const storeCodeFound = Persistence.getItem('store_view_code');
  const [storeCode, setStoreCode] = useState(storeCodeFound || 'default');

  const handleSetStore = useCallback(
    (payload: string) => setStoreCode(payload),
    []
  );

  useEffect(() => {
    Persistence.setItem('store_view_code', storeCode);
  }, [storeCode]);

  return (
    <StoreContext.Provider value={{ storeCode, handleSetStore }}>
      {children}
    </StoreContext.Provider>
  );
});

StoreProvider.displayName = 'StoreProvider';
