import Image from 'next/image';

const LoadingIndicator = () => {
  return (
    <div className="grid w-full h-[calc(100%-6rem)] align-center items-center justify-center my-12 mx-0 gap-12 m-8">
      <Image
        src="/assets/icons/loading_md.svg"
        width={100}
        height={100}
        alt="Loading"
      />
    </div>
  );
};

export default LoadingIndicator;
