import Skeleton, { SkeletonProps } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface iSkeleton extends SkeletonProps {
  width?: string | number;
  height?: string | number;
  count?: number;
}

const SkeletonComponent = ({
  width = '100%',
  height = '100%',
  count = 1,
  className,
  ...rest
}: iSkeleton) => {
  return (
    <Skeleton
      className={`rounded-[1rem] ${className}`}
      {...rest}
      width={width}
      height={height}
      count={count}
    />
  );
};

export default SkeletonComponent;
