import { useMemo } from 'react';
import { ProductCard } from '../ProductCard';
import { Slider, SliderProps } from '../Slider';
import { SingleProduct } from '@components/pages';
import classNames from 'classnames';
import styles from './productsSlider.module.scss';
import Link from 'next/link';
import ArrowRightSvg from '@assets/icons/arrow-right.svg';

interface ProductsSliderProps {
  products: SingleProduct[];
  settings: SliderProps;
  categoryData?: {
    name: string;
    url_key: string;
    url_path: string;
  };
  onClickItem?: (product: SingleProduct, position: number) => void;
}

type ListItemType = Array<{ id: 'last-item-id' } | SingleProduct>;

const SeeAllItem = (props: { classname: string; url_path: string }) => {
  return (
    <Link
      href={`/${props.url_path}/c`}
      className={classNames(
        'bg-[#F3F6FA] shadow-lg rounded p-3 pb-4 hover:border-tertiary-medium flex bg-white justify-start items-start overflow-hidden flex-col',
        props.classname
      )}
    >
      <div
        className={classNames(
          'w-full min-h-[400px] md:min-h-[435px]',
          'flex justify-center items-center gap-2'
        )}
      >
        <span className="text-secondary-medium font-bold text-lg">
          Ver todos
        </span>
        <ArrowRightSvg className="text-secondary-medium h-[16px]" />
      </div>
    </Link>
  );
};

export const ProductsSlider = ({
  products,
  settings,
  categoryData,
  onClickItem
}: ProductsSliderProps) => {
  const items: ListItemType = useMemo(() => {
    return [...products, { id: 'last-item-id' }];
  }, [products]);

  if (!products.length) return null;

  const productClassName = 'mx-1.5 mb-2 min-h-[460px] md:min-h-[488px]';
  const interceptSettings = {
    ...settings,
    itemClass: 'mx-auto',
    centerMode: false,
    infinite: false,
    renderDotsOutside: true
  } as SliderProps;

  return (
    <Slider
      className={items.length < 4 ? styles.slider : ''}
      {...interceptSettings}
    >
      {items.map((item, index) => {
        if (item.id === 'last-item-id' && !categoryData) {
          return null;
        }

        if (item.id === 'last-item-id' && categoryData) {
          return (
            <SeeAllItem
              key={index}
              classname={productClassName}
              url_path={categoryData.url_path}
            />
          );
        }

        return (
          <ProductCard
            className={classNames(productClassName, 'md:mx-3.5 w-auto')}
            key={item.id}
            product={item as SingleProduct}
            onClick={() => {
              onClickItem && onClickItem(item as SingleProduct, index + 1);
            }}
          />
        );
      })}
    </Slider>
  );
};
