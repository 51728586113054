import { Persistence } from '@utils';
import { useMemo } from 'react';

export const useAdminUser = () => {
  const admin_user = Persistence.getItem('admin_user');

  const isAdmin = useMemo(() => {
    return !!admin_user?.adminId;
  }, [admin_user]);

  return {
    adminUser: admin_user,
    isAdmin
  };
};
