import { useMutation } from '@apollo/client';
import ArrowRight from '@assets/icons/arrow-point-right.svg';
import { Button, ErrorMessage } from '@common';
import { SubscribeEmailToNewsletterDocument } from '@generated/graphql';
import { useGTMDataLayer } from '@hooks';
import classNames from 'classnames';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

interface PersonScore {
  email: string;
  className?: string;
  variant?: 'black-friday' | 'default';
}

export const SubscribeEmailToNewsletter = ({
  label = '',
  variant = 'default',
  textSuccessColor = 'text-success-dark',
  originId
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<PersonScore>();

  const [subscribe, { loading, error, data }] = useMutation(
    SubscribeEmailToNewsletterDocument
  );

  const { pushToDataLayer } = useGTMDataLayer();

  const onSubmit = ({ email }: PersonScore) => {
    subscribe({
      errorPolicy: 'all',
      variables: {
        email,
        origin: window.location.href + `#id=${originId}`
      }
    });
  };

  const didSubscribe = useMemo(() => {
    return !error && data?.subscribeEmailToNewsletter?.status === 'SUBSCRIBED';
  }, [data, error]);

  useEffect(() => {
    if (didSubscribe) {
      reset();
      pushToDataLayer('newsletter', { status: true });
    }
  }, [didSubscribe, pushToDataLayer, reset]);

  useEffect(() => {
    if (error) {
      pushToDataLayer('newsletter', { status: false });
    }
  }, [error, pushToDataLayer]);

  return (
    <form className="max-w-[578px] mx-auto" onSubmit={handleSubmit(onSubmit)}>
      <div
        className={classNames(
          'flex flex-col md:flex-row justify-center align-center',
          {
            'gap-4 mb-2': variant === 'black-friday'
          }
        )}
      >
        <input
          className={classNames(
            'bg-white rounded px-3 py-1 h-12 focus:border-primary-light active:border-primary-light border border-gray-light text-gray-dark',
            {
              'w-full lg:max-w-[320px]': variant === 'black-friday',
              'w-full': variant === 'default'
            }
          )}
          placeholder="Digite seu e-mail"
          {...register('email', {
            required: 'Este é um campo obrigatório.',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Formato de e-mail inválido.'
            }
          })}
        />
        <Button
          className={classNames(
            'w-full sm:w-1/6 px-10 py-4 items-center justify-center uppercase font-bold text-sm rounded-r text-white hover:bg-primary-dark disabled:bg-primary-light max-h-12 bg-primary-medium',
            {
              'lg:max-w-[240px]': variant === 'black-friday',
              'md:w-auto': variant === 'default'
            }
          )}
          type="submit"
          disabled={Boolean(errors.email?.message) || loading}
        >
          {label ? (
            <p className="m-0  items-center justify-center uppercase font-bold text-sm rounded text-white">
              {label}
            </p>
          ) : (
            <ArrowRight height={8} width={12} />
          )}
        </Button>
      </div>
      {(errors?.email?.message || error?.message) && (
        <ErrorMessage error={errors.email?.message || error?.message} />
      )}
      {didSubscribe && (
        <div className="bg-success-lightest px-4 px-2 mt-4 rounded-sm">
          <p className={`text-md ${textSuccessColor}`}>
            E-mail cadastrado com sucesso
          </p>
        </div>
      )}
    </form>
  );
};
