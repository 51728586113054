import { useCallback, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useCart } from './useCart';
import { useRouter, usePathname } from 'next/navigation';
import { CartTriggerFragment } from './useProductFullDetail';

/**
 * Routes to hide the mini cart on.
 */
const DENIED_MINI_CART_ROUTES = ['/checkout'];

const GET_ITEM_COUNT_QUERY = gql`
  query getItemCount($cartId: String!) {
    cart(cart_id: $cartId) {
      id
      ...CartTriggerFragment
    }
  }
  ${CartTriggerFragment}
`;

export const useCartTrigger = () => {
  const { cartId } = useCart();
  const [miniCartIsOpen, setMiniCartIsOpen] = useState(false);
  const router = useRouter();
  const pathname = usePathname();

  const { data } = useQuery(GET_ITEM_COUNT_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      cartId
    },
    skip: !cartId
  });
  const itemCount = data && !!data.cart ? data.cart.total_quantity : 0;
  const hideCartTrigger = DENIED_MINI_CART_ROUTES.includes(pathname);

  const handleLinkClick = useCallback(() => {
    // Send the user to the cart page.
    router.push('/cart');
  }, [router]);

  return {
    handleLinkClick,
    itemCount,
    miniCartIsOpen,
    hideCartTrigger,
    setMiniCartIsOpen
  };
};
