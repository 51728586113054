import LocationPin from '@assets/icons/location-pin.svg';
import ArrowRight from '@assets/icons/arrow-point-right.svg';
import Close from '@assets/icons/close.svg';
import { Button, Modal, ModalProps } from '@common';
import { useState } from 'react';
import axios from 'axios';
import { formatPostcode, isValidPostcode, Persistence } from '@utils';
import { useStoreSwitcher, useZipcode } from '@hooks';
import toast from 'react-hot-toast';
import InputMask from 'react-input-mask';

type WithOnCloseType = {
  onCloseModal(): void;
};

export const ZipcodeModal = ({
  onCloseModal,
  handleToggle,
  ...rest
}: Omit<ModalProps, 'children'> & WithOnCloseType) => {
  const [postcodeValue, setPostcodeValue] = useState('');

  const { handleSetZipcode } = useZipcode();
  const { handleSwitchStoreByPostcode } = useStoreSwitcher();

  const handleConfirmPostcode = async postcode => {
    try {
      const finalPostcode = postcodeValue || postcode;

      Persistence.setItem('postcode', formatPostcode(finalPostcode));
      await handleSwitchStoreByPostcode(finalPostcode);
      handleSetZipcode(formatPostcode(finalPostcode));
      handleToggle(finalPostcode);
    } catch (err) {
      toast.error(err.message || 'Ocorreu um erro ao aplicar o cep');
    }
  };

  const handleUseMyLocation = () => {
    navigator.geolocation.getCurrentPosition(
      async position => {
        const { latitude, longitude } = position.coords;

        const { data } = await axios.get(
          `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
        );

        if (data) {
          await handleConfirmPostcode(data.address.postcode);
        }
      },
      error => {
        if (error.PERMISSION_DENIED) {
          toast.error('A permissão de Geolocalização foi negada');
        } else {
          toast.error('Não foi possível identificar a localização');
        }
      }
    );
  };

  return (
    <Modal
      className="bottom-0 absolute w-full"
      overlayClassName="h-full bg-[rgba(0,_0,_0,_0.5)]"
      handleToggle={onCloseModal}
      {...rest}
    >
      <form className="z-50 overflow-auto p-8 flex flex-col w-full bg-primary-medium text-white">
        <div className="flex flex-col lg:items-center justify-center">
          <Close
            className="absolute right-10 top-5 cursor-pointer"
            onClick={onCloseModal}
          />
          <h1 className="lg:text-center text-xl font-bold mb-2 lg:mb-0">
            Informe o CEP de envio para uma melhor navegação.
          </h1>
          <h2 className="text-sm">
            A disponibilidade e preço podem variar conforme a região.{' '}
          </h2>
        </div>

        <div className="mt-5 lg:mt-0 flex flex-col lg:flex-row items-center justify-center">
          <div className="w-full lg:w-auto">
            <p className="text-sm font-bold mb-4">CEP</p>

            <span className="flex">
              <InputMask
                inputMode="tel"
                mask="99999-999"
                placeholder="00000-000"
                className="px-4 h-12 rounded text-gray-darkest"
                onChange={e => setPostcodeValue(e.target.value)}
              />

              <Button
                type="submit"
                onClick={ev => {
                  ev.preventDefault();
                  handleConfirmPostcode(null);
                }}
                disabled={!isValidPostcode(postcodeValue)}
                className="ml-2 px-6 py-2 h-12 bg-secondary-darkest disabled:bg-secondary-light disabled:filter disabled:brightness-50 rounded flex items-center"
              >
                Confirmar
                <ArrowRight
                  height={8}
                  width={12}
                  className="ml-4 self-center"
                />
              </Button>
            </span>

            <a
              className="text-sm underline block mb-6 lg:mb-0 mt-2 underline-offset-1"
              href="http://www.buscacep.correios.com.br/sistemas/buscacep/"
              target="_blank"
              rel="noreferrer"
            >
              Não sei meu CEP?
            </a>
          </div>

          <div className="hidden lg:block h-36 w-px my-4 mx-12 bg-white opacity-30" />

          <div className="w-full lg:w-auto">
            <p className="text-sm mb-4">
              Se preferir você pode compartilhar sua localização:
            </p>

            <button
              onClick={handleUseMyLocation}
              type="button"
              className="bg-secondary-darkest rounded w-full h-12 px-6 py-2 lg:mb-7 flex justify-center items-center"
            >
              USAR MINHA LOCALIZAÇÃO
              <LocationPin
                height={16}
                width={16}
                className="ml-auto lg:ml-4 self-center"
              />
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
