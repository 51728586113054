import { CartContext, iCartContext } from '@contexts/cart';
import { useContext } from 'react';

export const useCart = (): iCartContext => {
  const context = useContext(CartContext);

  if (!context) {
    throw new Error('Cart Context must be within Provider');
  }

  return context;
};
