import { ReactNode } from 'react';

interface ContentWrapperProps {
  className: string;
  wrapperClassName?: string;
  children: ReactNode;
}

export const ContentWrapper = ({
  className = '',
  wrapperClassName = '',
  children,
  ...rest
}: ContentWrapperProps) => {
  const container = `w-full flex flex-col items-center justify-center ${className}`;
  const wrapper = `w-full xl:max-w-wrapper ${wrapperClassName}`;

  return (
    <div className={container} {...rest}>
      <div className={wrapper}>{children}</div>
    </div>
  );
};
