import classNames from 'classnames';
import { Price } from './Price';

interface ProductPriceProps {
  price: {
    discount?: {
      percent_off: number;
    };
    final_price: {
      value: number;
      currency: string;
    };
    regular_price?: {
      value: number;
      currency: string;
    };
  };
  priceClassName?: string;
}

export const ProductPrice = ({
  price,
  priceClassName = 'text-md'
}: ProductPriceProps) => {
  return (
    <div className="flex flex-row flex-wrap justify-start items-center">
      {!price.regular_price?.value ||
      price.regular_price.value === price.final_price.value ? (
        <span
          className={classNames(
            'text-primary-medium font-bold',
            priceClassName
          )}
        >
          <Price
            currencyCode={price.final_price.currency}
            value={price.final_price?.value}
          />
        </span>
      ) : (
        <>
          <span className="text-md text-gray-medium line-through">
            <Price
              currencyCode={price.regular_price.currency}
              value={price.regular_price?.value}
            />
          </span>
          <span className="ml-2 text-xs text-white font-bold bg-primary-dark rounded px-1">
            -{price.discount?.percent_off.toFixed(0)}%
          </span>

          <div className="w-full">
            <span
              className={classNames(
                'text-primary-medium font-bold flex flex-row items-center',
                priceClassName
              )}
            >
              <Price
                currencyCode={price.final_price.currency}
                value={price.final_price.value}
              />{' '}
            </span>
          </div>
        </>
      )}
    </div>
  );
};
