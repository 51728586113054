import { Rating } from 'react-simple-star-rating';
import classNames from 'classnames';
import styles from './stars.module.scss';
import { ComponentProps } from 'react';

type StarsProps = ComponentProps<typeof Rating> & {
  className?: string;
  readonly?: boolean;
  iconsCount?: number;
  initialValue: number;
  emptyColor?: string;
  fillColor?: string;
  size?: number;
};

export const Stars = ({
  className = 'mr-1.5 cursor-default',
  emptyColor = '#DEDEDE',
  fillColor = '#FF9800',
  ...props
}: StarsProps) => {
  return (
    <Rating
      readonly
      iconsCount={5}
      emptyColor={emptyColor}
      fillColor={fillColor}
      size={16}
      titleSeparator="de"
      {...props}
      className={classNames(
        { 'cursor-pointer': !props?.readonly },
        className,
        styles?.stars
      )}
    />
  );
};
