import TelephoneIcon from '@assets/icons/telephone.svg';
import WhatsappIcon from '@assets/icons/whatsapp-icon.svg';
import { useGTMDataLayer } from '@hooks';

export const FloatingButtons = () => {
  const { pushToDataLayer } = useGTMDataLayer();

  return (
    <ul className="fixed z-30	right-0 xl:right-half-wrapper bottom-[10vh] mr-3 lg:mr-6">
      <li className="max-h-14 overflow-hidden mb-2 lg:mb-4">
        <a
          href="tel:+551130958482"
          target="_blank"
          rel="noreferrer"
          className="flex items-center justify-center bg-darkMode-dark min-h-12 min-w-12 rounded-full"
          onClick={() => {
            pushToDataLayer('phoneTrigger', {});
          }}
        >
          <TelephoneIcon className="keep-color" height={22} />
        </a>
      </li>
      <li>
        <a
          href="https://wa.me/551130958482?text=Ol%C3%A1,%20como%20posso%20comprar%20pelo%20WhatsApp?"
          target="_blank"
          rel="noreferrer"
          className="flex items-center justify-center min-h-12 min-w-12 rounded-full bg-[#0DBF43] text-white"
          onClick={() => {
            pushToDataLayer('whatsappTrigger', {});
          }}
        >
          <WhatsappIcon className="-mr-[1px]" width={23} />
        </a>
      </li>
    </ul>
  );
};
