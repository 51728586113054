import classNames from 'classnames';
import { useCallback } from 'react';
import Check from '@assets/icons/check.svg';

interface FilterItemProps {
  filterApi: {
    toggleItem: ({ group, item }) => void;
  };
  filterState: any;
  group: string;
  item: {
    title: string;
    value: string | number;
  };
  applyFilters: () => void;
}

export const FilterItem = ({
  filterApi,
  filterState,
  group,
  item,
  applyFilters
}: FilterItemProps) => {
  const { toggleItem } = filterApi;
  const { title, value } = item;
  const isSelected = filterState && filterState.has(item);

  const handleClick = useCallback(() => {
    toggleItem({ group, item });
    applyFilters();
  }, [group, item, toggleItem, applyFilters]);

  return (
    <label
      className={classNames(
        'text-gray-dark mb-4 flex items-center cursor-pointer',
        {
          'font-bold': isSelected
        }
      )}
    >
      <div className="w-auto mr-4">
        <div className="w-4 h-4 border-[1px] border-primary-medium rounded flex items-center justify-center">
          {isSelected && <Check width={8} className="text-primary-medium" />}
        </div>

        <input
          className="sr-only"
          type="checkbox"
          name={`${title}-${value}`}
          checked={isSelected}
          onClick={handleClick}
        />
      </div>
      <span className="w-full">{title}</span>
    </label>
  );
};
