export const CACHE_PERSIST_PREFIX = 'apollo-cache-persist';

/**
 * Deletes specific entry/entries from the apollo cache and then tries to
 * persist the deletions.
 *
 * @param {ApolloClient} client apollo client instance
 * @param {Function} predicate a matching function
 */
export const deleteCacheEntry = async (client, predicate) => {
  await deleteActiveCacheEntry(client, predicate);
  await deleteInactiveCachesEntry(client, predicate);
};

const deleteActiveCacheEntry = async (client, predicate) => {
  // If there is no client or cache then just back out since it doesn't matter :D
  if (
    !client ||
    !client.cache ||
    !client.cache.data ||
    !client.cache.data.data
  ) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.warn(
        'Apollo Cache entry deletion attempted without client or cache.'
      );
    }
    return;
  }

  // Remove from the active cache.
  Object.keys(client.cache.data.data).forEach(key => {
    if (predicate(key)) {
      client.cache.data.delete(key);
    }
  });

  // Immediately persist the cache changes to the active cache storage.
  if (client.persistor) {
    await client.persistor.persist();
  }
};

const deleteInactiveCachesEntry = async (client, predicate) => {
  if (!client || !client.persistor) return;

  const activeApolloCacheLocalStorageKey =
    client.persistor.persistor.storage.key;

  const isAnInactiveApolloCache = ([key]) => {
    return (
      key.startsWith(CACHE_PERSIST_PREFIX) &&
      key !== activeApolloCacheLocalStorageKey
    );
  };

  Object.entries(localStorage)
    .filter(isAnInactiveApolloCache as any)
    .forEach(([inactiveCacheKey, inactiveCacheValue]) => {
      const inactiveApolloCache = JSON.parse(inactiveCacheValue);

      Object.keys(inactiveApolloCache).forEach(key => {
        if (predicate(key)) {
          delete inactiveApolloCache[key];
        }
      });

      // We're done deleting keys that match the predicate,
      // but we've only mutated the object in memory.
      // Write the updated inactive cache back out to localStorage.
      localStorage.setItem(
        inactiveCacheKey,
        JSON.stringify(inactiveApolloCache)
      );
    });
};

/**
 * Deletes all references to Cart from the apollo cache including entries that
 * start with "$" which were automatically created by Apollo InMemoryCache.
 *
 * @param {ApolloClient} client
 */
export const clearCartDataFromCache = async client => {
  await deleteCacheEntry(client, key => key.match(/^\$?Cart/));

  // Any cart subtypes that have key fields must be manually cleared.
  // TODO: we may be able to use cache.evict here instead.
  await deleteCacheEntry(client, key => key.match(/^\$?AppliedGiftCard/));
  await deleteCacheEntry(client, key => key.match(/^\$?ShippingCartAddress/));
  await deleteCacheEntry(client, key =>
    key.match(/^\$?AvailableShippingMethod/)
  );
};
