import { useMemo } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { Slider, SliderProps } from '@components/common/Slider';

const settings: SliderProps = {
  infinite: true,
  autoPlay: true,
  autoPlaySpeed: 4000,
  numberOfSlides: 1,
  slidesToSlide: 1,
  arrows: false,
  showDots: false
};

export const LineBanner = ({ data }) => {
  const infoClassName =
    'p-2 w-full bg-secondary-medium text-white text-sm font-bold flex items-center justify-center min-h-[48px] pointer-events-none line-clamp-1';

  const lineBannerData = useMemo(() => {
    return data?.cmsBlocks?.block_content || [];
  }, [data]);

  return (
    <>
      {!isEmpty(lineBannerData) && (
        <div className="w-full h-12">
          <Slider numberOfSlides={1} {...settings}>
            {lineBannerData?.map(info =>
              info?.url ? (
                <div
                  key={info.content}
                  className={classNames(
                    infoClassName,
                    'bg-secondary-medium cursor-pointer'
                  )}
                >
                  <a href={info.url}>{info.content}</a>
                </div>
              ) : (
                <div
                  key={info.content}
                  className={classNames(infoClassName, 'bg-secondary-medium')}
                >
                  <span>{info.content}</span>
                </div>
              )
            )}
          </Slider>
        </div>
      )}
    </>
  );
};
