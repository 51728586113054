import { useQuery } from '@apollo/client';
import { FilterBlock } from '@components/pages/ListProductWithFilter/FilterBlock';
import {
  GetCategoryDocument,
  GetFilterInputsQueryDocument,
  GetProductFiltersByCategoryDocument,
  GetProductFiltersBySearchDocument
} from '@generated/graphql';
import { useFilterState } from '@hooks';
import { getSearchFromState, getStateFromSearch, stripHtml } from '@utils';
import {
  useParams,
  usePathname,
  useRouter,
  useSearchParams
} from 'next/navigation';
import { useCallback, useEffect, useMemo, useState } from 'react';

export const useListProductWithFilterPage = () => {
  const router = useRouter();
  const query = useParams();
  const urlSearchParams = useSearchParams();
  const searchParams = Object.fromEntries(useSearchParams().entries());
  const pathname = usePathname();
  const search = searchParams?.query as string;

  let url_path_mounted = '';
  if (query?.url_key && Array.isArray(query.url_key)) {
    url_path_mounted = query.url_key.join('/');
  }

  const { data: category } = useQuery(GetCategoryDocument, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      filters: {
        url_path: { eq: url_path_mounted }
      }
    }
  });

  let queryApollo;
  let variables = {};

  if (search) {
    queryApollo = GetProductFiltersBySearchDocument;
    variables = { search };
  } else {
    queryApollo = GetProductFiltersByCategoryDocument;
    variables = {
      filters: {
        category_id: { eq: category?.categoryList[0]?.id }
      }
    };
  }

  const categoriesPath = useCallback(() => {
    if (query.url_key && Array.isArray(query.url_key)) {
      return query.url_key.join('/');
    }
    return '';
  }, [query]);

  const { data: filterData } = useQuery(queryApollo, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: variables
  });

  const filters = useMemo(
    () => filterData?.products?.aggregations ?? [],
    [filterData]
  );

  const attributeCodes = useMemo(
    () => filters.map(({ attribute_code }) => attribute_code),
    [filters]
  );

  const { data: introspectionData } = useQuery(GetFilterInputsQueryDocument);

  // Create a set of disabled filters.
  const DISABLED_FILTERS = useMemo(() => {
    const disabled = new Set();
    // Disable category filtering when not on a search page.
    if (pathname !== '/search.html' && pathname !== '/search') {
      disabled.add('category_id');
    }

    return disabled;
  }, [pathname]);

  // Get "allowed" filters by intersection of filter attribute codes and
  // schema input field types. This restricts the displayed filters to those
  // that the api will understand.
  const possibleFilters = useMemo(() => {
    const nextFilters = new Set();

    const inputFields = introspectionData
      ? introspectionData.__type.inputFields
      : [];

    // perform mapping and filtering in the same cycle
    for (const { name } of inputFields) {
      const isValid = attributeCodes.includes(name);
      const isEnabled = !DISABLED_FILTERS.has(name);

      if (isValid && isEnabled) {
        nextFilters.add(name);
      }
    }

    return nextFilters;
  }, [DISABLED_FILTERS, attributeCodes, introspectionData]);

  const [filterState, filterApi] = useFilterState();

  const [filterNames, filterKeys, filterItems] = useMemo(() => {
    const names = new Map();
    const keys = new Set();
    const itemsByGroup = new Map();

    for (const filter of filters) {
      const { options, label: name, attribute_code: group } = filter;

      // If this aggregation is not a possible filter, just back out.
      if (possibleFilters.has(group)) {
        const items: any = [];

        // add filter name
        names.set(group, name);

        // add filter key permutations
        keys.add(`${group}[filter]`);

        // add items
        for (const { label, value } of options) {
          items.push({ title: stripHtml(label), value });
        }
        itemsByGroup.set(group, items);
      }
    }

    return [names, keys, itemsByGroup];
  }, [filters, possibleFilters]);

  const [isApplying, setIsApplying] = useState(false);

  const handleApply = useCallback(() => {
    setIsApplying(true);
  }, []);

  // on apply, write filter state to location
  const queriesUrl = (
    urlSearchParams?.toString()
      ? `${pathname}?${urlSearchParams.toString()}`
      : pathname
  )
    .replace(`${pathname}.html`, '')
    .replace(pathname, '')
    .replace(categoriesPath(), '')
    .replace('/c', '')
    .replace('?', '')
    .replace(`&page=${searchParams?.page}`, '&page=1');

  //clear filter states when url doesn't have query params
  useEffect(() => {
    if (queriesUrl == '/') {
      filterApi.clear();
    }
  }, [filterApi, queriesUrl]);

  useEffect(() => {
    if (isApplying) {
      const nextSearch = getSearchFromState(
        queriesUrl,
        filterKeys,
        filterState
      );

      if (category?.categoryList[0]?.id) {
        router.push(`/${categoriesPath()}/c${nextSearch}`);
      } else {
        router.push(`${pathname}${nextSearch}`);
      }

      // mark the operation as complete
      setIsApplying(false);
    }
  }, [
    categoriesPath,
    category,
    filterKeys,
    filterState,
    isApplying,
    pathname,
    queriesUrl,
    router,
    search
  ]);

  const [hasAppliedInitialValues, setHasAppliedInitialValues] = useState(false);
  // on drawer toggle, read filter state from location
  if (!hasAppliedInitialValues && filterKeys.size > 0 && filterItems.size > 0) {
    const nextState = getStateFromSearch(queriesUrl, filterKeys, filterItems);
    filterApi.setItems(nextState);
    setHasAppliedInitialValues(true);
  }
  const filtersList = useMemo(
    () =>
      Array.from(filterItems, ([group, items]) => {
        let blockState;
        if (filterState instanceof Map) {
          blockState = filterState?.get(group);
        }
        const groupName = filterNames.get(group);

        return (
          <FilterBlock
            key={group}
            filterApi={filterApi}
            filterState={blockState}
            group={group}
            items={items}
            name={groupName === 'Category' ? 'Categoria' : groupName}
            applyFilters={handleApply}
          />
        );
      }).reverse(),
    [filterApi, filterItems, filterNames, filterState, handleApply]
  );

  const hasFilters = !!(filters && filters.length) || false;

  return {
    filtersList,
    search,
    filters,
    filterApi,
    hasFilters,
    categoriesPath
  };
};
