import classNames from 'classnames';

interface FlavorsTagProps {
  quantity: number;
  className?: string;
}

export const FlavorsTag = ({
  quantity,
  className = 'mt-4 text-gray-dark'
}: FlavorsTagProps) => {
  if (!quantity || quantity < 2) {
    return null;
  }

  return (
    <div
      className={classNames(
        'px-2 bg-gray-lightest rounded text-center',
        className
      )}
    >
      <span className="text-sm">{quantity} sabores</span>
    </div>
  );
};
