import { DELIMITER } from '@utils';

export const getLocation = (searchValue, categoryId, label) => {
  // start with the current uri
  const uri = new URL('/search', window.location as any);
  // update the query params
  uri.searchParams.set('query', searchValue);
  uri.searchParams.set(
    'category_id[filter]',
    `${label}${DELIMITER}${categoryId}`
  );

  return uri.href;
};

export const sufixParse = (sufix: string) => (url: string) => {
  return url.replace(/(.html)(\D|\d)*/, sufix);
};
