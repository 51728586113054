import { ReactNode, Suspense } from 'react';
import LoadingIndicator from '../LoadingIndicator';

interface AppWrapperProps {
  children: ReactNode;
}

export const AppWrapper = ({ children }: AppWrapperProps) => {
  return <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>;
};
