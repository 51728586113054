/**
 * Persistence layer with expiration based on localStorage.
 */

export class Persistence {
  static getRawItem(name) {
    if (typeof window === 'undefined') {
      return undefined;
    }
    return window.localStorage.getItem(name);
  }

  static getItem(name) {
    if (typeof window === 'undefined') {
      return undefined;
    }
    const now = Date.now();
    const item = window.localStorage.getItem(name);
    if (!item) {
      return undefined;
    }
    try {
      const { ttl, timeStored } = JSON.parse(item);
      if (ttl && now - timeStored > ttl * 1000) {
        window.localStorage.removeItem(name);
      }
    } catch {
      return undefined;
    }

    try {
      const { value } = JSON.parse(item);
      return JSON.parse(value);
    } catch {
      this.removeItem(name);
      return undefined;
    }
  }

  static setItem(name, value, ttl?) {
    if (typeof window === 'undefined') {
      return undefined;
    }

    const timeStored = Date.now();
    window.localStorage.setItem(
      name,
      JSON.stringify({
        value: JSON.stringify(value),
        timeStored,
        ttl
      })
    );
  }

  static removeItem(name) {
    if (typeof window === 'undefined') {
      return undefined;
    }

    window.localStorage.removeItem(name);
  }
}
