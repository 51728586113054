import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { compact, isEmpty } from 'lodash';
import { useStore } from './useStore';
import { useCart } from './useCart';
import { configuredVariant, deriveErrorMessage, priceFormat } from '@utils';
import { useRouter } from 'next/navigation';
import {
  getBreadcrumbCategory,
  useCustomAttributes
} from './useProductFullDetail';
import { RemoveItemForMiniCartDocument } from '@generated/graphql';

/**
 *
 * @param {Function} props.setIsOpen - Function to toggle the mini cart
 * @param {DocumentNode} props.operations.miniCartQuery - Query to fetch mini cart data
 * @param {DocumentNode} props.operations.removeItemMutation - Mutation to remove an item from cart
 *
 * @returns {
 *      closeMiniCart: Function,
 *      errorMessage: String,
 *      handleEditCart: Function,
 *      handleProceedToCheckout: Function,
 *      handleRemoveItem: Function,
 *      loading: Boolean,
 *      productList: Array<>,
 *      subTotal: Number,
 *      totalQuantity: Number
 *      configurableThumbnailSource: String
 *  }
 */
export const useMiniCart = (setIsOpen?: Dispatch<SetStateAction<boolean>>) => {
  const {
    cartToken,
    loading: miniCartLoading,
    cart,
    fetchCart: fetchMiniCart
  } = useCart();
  const router = useRouter();

  const { currentStore } = useStore();

  const configurableThumbnailSource = useMemo(() => {
    if (currentStore) {
      return currentStore.configurable_thumbnail_source;
    }
  }, [currentStore]);

  const [
    removeItem,
    {
      loading: removeItemLoading,
      called: removeItemCalled,
      error: removeItemError
    }
  ] = useMutation(RemoveItemForMiniCartDocument);

  const totalQuantity = useMemo(() => {
    if (cart) {
      return cart.total_quantity;
    }
  }, [cart]);

  const { getProductCustomAttributes } = useCustomAttributes();

  const productList = useMemo(() => {
    if (!isEmpty(cart)) {
      const items = cart.items.map(item => {
        const isConfigurable = item?.product?.variants?.length > 0;
        const configured_variant =
          configuredVariant(item?.configurable_options, item?.product) ||
          item?.product;

        const custom_attributes =
          getProductCustomAttributes(configured_variant);
        return {
          ...item,
          product: {
            ...item?.product,
            ...configured_variant,
            category: getBreadcrumbCategory(item?.product.categories),
            is_configurable: isConfigurable,
            parent_sku: isConfigurable ? item?.product?.sku : null,
            ...custom_attributes
          }
        };
      });

      return compact(items);
    }
  }, [getProductCustomAttributes, cart]);

  const closeMiniCart = useCallback(() => {
    if (setIsOpen) {
      setIsOpen(false);
    }
  }, [setIsOpen]);

  const handleRemoveItem = useCallback(
    async id => {
      try {
        const cartCreated = await cartToken();

        await removeItem({
          variables: {
            cartId: cartCreated,
            itemId: id
          }
        });
        await fetchMiniCart();
      } catch (e) {
        // Error is logged by apollo link - no need to double log.
      }
    },
    [cartToken, fetchMiniCart, removeItem]
  );

  const handleProceedToCheckout = useCallback(() => {
    if (setIsOpen) {
      setIsOpen(false);
    }
    router.push('/checkout');
  }, [router, setIsOpen]);

  const handleEditCart = useCallback(() => {
    if (setIsOpen) {
      setIsOpen(false);
    }
    router.push('/cart');
  }, [router, setIsOpen]);

  const derivedErrorMessage = useMemo(
    () => deriveErrorMessage([removeItemError]),
    [removeItemError]
  );

  const subTotal = useMemo(() => {
    if (!isEmpty(cart)) {
      return priceFormat({
        value: cart?.prices?.subtotal_excluding_tax?.value || 0
      });
    }
  }, [cart]);

  return {
    closeMiniCart,
    errorMessage: derivedErrorMessage,
    handleEditCart,
    handleProceedToCheckout,
    handleRemoveItem,
    loading: miniCartLoading || (removeItemCalled && removeItemLoading),
    productList,
    totalQuantity,
    configurableThumbnailSource,
    fetchMiniCart,
    subTotal
  };
};
