import { useQuery } from '@apollo/client';
import { GetCustomerOrderCountDocument } from '@generated/graphql';

/**
 * Hook that returns the number of purchases for the current customer
 * If the current user isn't logged in, return -1
 * @returns number
 */
const useCustomerOrderCount = ({ isSignedIn }) => {
  const { data } = useQuery(GetCustomerOrderCountDocument, {
    skip: !isSignedIn
  });

  return data?.customer?.orders?.total_count ?? -1;
};

export default useCustomerOrderCount;
