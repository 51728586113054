import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import styles from './htmlBlock.module.scss';
/**
 * Creates an HTMLElement with
 * [dangerouslySetInnerHTML](https://pt-br.reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml)
 * in it.
 *
 * @typedef {Object} HTMLBlockProps
 * @property {string} content a string containing some HTML content,
 * usually coming from Magento admin
 * @property {ElementType<HTMLAttributes>} htmlTag an HTML tag
 * to be rendered wrapping the content
 * @property {HTMLAttributes} rest the rest of the props
 *
 * @param {HTMLBlockProps} props A string content and a HTML tag, defaulting to a div
 * @returns {React.VFC<HTMLBlockProps} A JSX Element
 */
interface HTMLBlockProps extends HTMLAttributes<HTMLElement> {
  content: string;
  className?: string;
}

export const HTMLBlock = ({
  content,
  className = '',
  ...rest
}: HTMLBlockProps) => {
  return (
    <div
      className={classNames('text-gray-dark', className, styles.htmlBlock)}
      dangerouslySetInnerHTML={{
        __html: content.replace(/<p>&nbsp;<\/p>/gi, '').replace(/&nbsp;/gi, '')
      }}
      {...rest}
    />
  );
};
