import styles from './richContent.module.scss';
import he from 'he';
import classNames from 'classnames';
import { useQuery } from '@apollo/client';
import { GET_CMS_BLOCKS } from '@components/pages/Home/NewsBanner';
import { memo } from 'react';

interface RichContentProps {
  html: string;
  className?: string;
}

export const RichContent = memo(({ html, className }: RichContentProps) => {
  return (
    <div
      className={classNames(styles.root, className)}
      dangerouslySetInnerHTML={{ __html: he.decode(html) }}
    />
  );
});

RichContent.displayName = 'RichContent';

interface CMSBlockProps {
  identifiers: string;
  className?: string;
}

export const CMSBlock = ({ identifiers, className }: CMSBlockProps) => {
  const { data } = useQuery(GET_CMS_BLOCKS, {
    variables: { identifiers }
  });
  const html = data?.cmsBlocks?.items[0]?.content;
  return html && <RichContent className={className} html={html} />;
};
