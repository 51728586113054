import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import Link from 'next/link';

export interface CategoryProps {
  id: number;
  name: string;
  include_in_menu: number;
  position: number;
  url_path: string;
  category_link_url: string;
  children: CategoryProps[];
}

export interface CategoryListProps {
  id: number;
  name: string;
  children: CategoryProps[];
  url_path: string | null;
  category_link_url: string | null;
}

export const GET_MEGA_MENU = gql`
  query getMegaMenu {
    categoryList {
      id
      name
      children {
        id
        include_in_menu
        name
        position
        url_path
        url_suffix
        category_link_url
        children {
          id
          include_in_menu
          name
          position
          url_path
          url_suffix
          category_link_url
          children {
            id
            include_in_menu
            name
            position
            url_path
            url_suffix
            category_link_url
          }
        }
      }
    }
  }
`;

const formatLink = (linkCategory, linkLandingPageOnCategory) => {
  if (linkLandingPageOnCategory)
    return `/${linkLandingPageOnCategory.replace(/^\//, '')}/l`;
  return `/${linkCategory}/c`;
};

const SubCategoryMenu = (subCategory: CategoryProps) => {
  if (!subCategory.include_in_menu) return <></>;

  return subCategory?.children?.length > 0 ? (
    <div className="flex flex-col p-5">
      <Link
        href={formatLink(subCategory.url_path, subCategory.category_link_url)}
        className="border-b-2 border-transparent hover:border-primary-medium text-sm"
      >
        {subCategory.name}
      </Link>

      {subCategory?.children?.map(cat => (
        <Link
          key={cat.id}
          href={formatLink(cat.url_path, cat.category_link_url)}
          className="font-medium border-b-2 border-transparent hover:border-primary-medium mt-2 text-sm"
        >
          {cat.name}
        </Link>
      ))}
    </div>
  ) : (
    <Link
      href={formatLink(subCategory.url_path, subCategory.category_link_url)}
      className="border-b-2 border-transparent hover:border-primary-medium pt-5 mx-5"
    >
      {subCategory.name}
    </Link>
  );
};

const CategoryList = (category: CategoryProps) => {
  if (!category.include_in_menu) return <></>;

  return (
    <div className="group">
      <Link
        className="group border-b-4 min-h-12 px-3 flex items-center border-transparent hover:border-primary-medium text-sm font-bold dark:text-white"
        href={formatLink(category.url_path, category.category_link_url)}
        prefetch={false}
      >
        {category.name}
      </Link>

      {!isEmpty(category?.children) && (
        <div className="bg-primary-lightest shadow-md absolute hidden z-20 left-0 justify-center items-start w-full p-6 group-hover:flex flex-wrap">
          {category.children.map(subCategory => (
            <SubCategoryMenu key={subCategory.id} {...subCategory} />
          ))}
        </div>
      )}
    </div>
  );
};

export const MegaMenu = () => {
  const { data, loading } = useQuery(GET_MEGA_MENU);

  const categoryList = useMemo(() => {
    const categories = data?.categoryList[0] as CategoryListProps;

    return categories?.children || [];
  }, [data]);

  if (loading) return <></>;

  return (
    <nav className="w-full lg:flex hidden items-center justify-evenly font-bold text-primary-medium gtm-menu dark:bg-primary-darkest">
      {categoryList?.map(category => (
        <CategoryList key={category.id} {...category} />
      ))}
    </nav>
  );
};
