import { useCart, useRecurrence, useStore } from '@hooks';
import { ProgressBar } from '../ProgressBar';
import { useMemo } from 'react';
import { priceFormat } from '@utils';
import TruckSvg from '@assets/icons/truck.svg';
import SignatureSvg from '@assets/icons/logo-signature-small.svg';

export function FreeShippingProgressBar() {
  const { cart, isSignature } = useCart();
  const { recurrenceConfig } = useRecurrence();
  const { currentStore } = useStore();

  const freeShippingValue = Number(
    currentStore?.freeshipping_custom_value || 0
  );

  const totalValue = useMemo(() => {
    if (cart?.prices?.grand_total?.value) {
      const shippingCost =
        cart?.shipping_addresses[0]?.available_shipping_methods[0]?.amount
          .value || 0;

      return Math.max(0, cart?.prices?.grand_total?.value - shippingCost);
    }

    return 0;
  }, [cart?.prices?.grand_total?.value, cart?.shipping_addresses]);

  const remainingValue = useMemo(() => {
    if (isSignature) {
      return priceFormat({
        value: Number(recurrenceConfig.minimun_value) - totalValue
      });
    }

    return priceFormat({
      value: Number(freeShippingValue) - totalValue
    });
  }, [
    freeShippingValue,
    isSignature,
    recurrenceConfig.minimun_value,
    totalValue
  ]);

  const color = isSignature ? 'secondary' : 'success';
  const targetValue = isSignature
    ? Number(recurrenceConfig.minimun_value || 0)
    : freeShippingValue;

  if (!freeShippingValue && !isSignature) {
    return null;
  }

  return (
    <ProgressBar current={totalValue} target={targetValue} color={color}>
      <div className="mt-1 text-sm flex justify-center items-center">
        {isSignature ? (
          <div className="flex items-center">
            <div className="mr-2">
              <SignatureSvg />
            </div>
            {totalValue >= targetValue ? (
              <span>
                O valor mínimo de{' '}
                <span className="font-bold">
                  {priceFormat({ value: targetValue })}
                </span>{' '}
                foi atingido.
              </span>
            ) : (
              <span>
                Adicione mais{' '}
                <span className="font-bold">{remainingValue}</span> para
                completar sua compra programada
              </span>
            )}
          </div>
        ) : (
          <div className="flex items-center">
            <TruckSvg className="mr-2 keep-color" />
            {totalValue >= targetValue ? (
              <span className="text-primary-medium">
                Você ganhou <span className="font-bold">frete grátis</span>
              </span>
            ) : (
              <span className="text-gray-dark">
                Faltam <span className="font-bold">{remainingValue}</span> para
                o frete grátis
              </span>
            )}
          </div>
        )}
      </div>
    </ProgressBar>
  );
}
