import Carousel, {
  CarouselProps,
  ArrowProps as CustomArrowProps,
  DotProps as CustomDotProps
} from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import ArrowPrev from '@assets/icons/arrow-left.svg';
import ArrowNext from '@assets/icons/arrow-right.svg';
import classNames from 'classnames';
import styles from './slider.module.scss';

interface DotProps extends CustomDotProps {
  activeDotsClass?: string;
  inactiveDotsClass?: string;
}

interface ArrowProps extends CustomArrowProps {
  side: 'prev' | 'next';
  className?: string | undefined;
  customArrow?: string;
  customArrowColor?: string;
  style?: React.CSSProperties | undefined;
}

export const Dots = ({
  inactiveDotsClass,
  activeDotsClass,
  active,
  onClick
}: DotProps) => {
  return (
    <div
      onClick={onClick}
      className={`ml-2 mb-4 cursor-pointer h-2 ${active ? `bg-primary-medium w-[24px] rounded-md ${activeDotsClass}` : `bg-gray-light w-2 rounded-full ${inactiveDotsClass}`}`}
    />
  );
};

export const Arrow = ({
  side,
  customArrow,
  customArrowColor,
  className,
  onClick,
  style
}: Partial<ArrowProps>) => {
  return (
    <button
      style={style}
      onClick={onClick}
      className={classNames(
        `before:content-[none] w-8 h-8 ${customArrow ? customArrow : 'bg-primary-medium hover:bg-primary-dark'} text-white z-20 rounded-full transition-colors ease-in-out`,
        {
          'left-0': side === 'prev'
        },
        {
          'right-0': side === 'next'
        },
        className
      )}
      type="button"
    >
      {side === 'prev' ? (
        <ArrowPrev
          width={12}
          height={12}
          className={`mx-auto ${customArrowColor ? customArrowColor : 'text-white-light'}`}
        />
      ) : (
        <ArrowNext
          width={12}
          height={12}
          className={`mx-auto ${customArrowColor ? customArrowColor : 'text-white-light'}`}
        />
      )}
    </button>
  );
};

export interface SliderProps extends Partial<CarouselProps> {
  customArrow?: string;
  customArrowColor?: string;
  arrowClassName?: string;
  activeDotsClass?: string;
  inactiveDotsClass?: string;
  numberOfSlides?: number;
  numberOfSlidesForTablet?: number;
  numberOfSlidesForMobile?: number;
}

export const Slider = ({
  children,
  numberOfSlides = 1,
  numberOfSlidesForMobile,
  numberOfSlidesForTablet,
  inactiveDotsClass,
  activeDotsClass,
  arrowClassName = 'hidden md:block absolute',
  customArrow = '',
  customArrowColor = '',
  ...props
}: SliderProps) => {
  return (
    <div className={` ${props.renderDotsOutside ? 'relative pb-8' : ''}`}>
      <Carousel
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        slidesToSlide={1}
        infinite
        focusOnSelect={false}
        arrows
        showDots
        pauseOnHover
        renderArrowsWhenDisabled={true}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        customDot={
          <Dots
            activeDotsClass={activeDotsClass}
            inactiveDotsClass={inactiveDotsClass}
          />
        }
        customLeftArrow={
          <Arrow
            className={arrowClassName}
            customArrow={customArrow}
            customArrowColor={customArrowColor}
            side="prev"
          />
        }
        customRightArrow={
          <Arrow
            className={arrowClassName}
            customArrow={customArrow}
            customArrowColor={customArrowColor}
            side="next"
          />
        }
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024
            },
            items: numberOfSlides,
            partialVisibilityGutter: props.partialVisible && 30
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0
            },
            items: numberOfSlidesForMobile || numberOfSlides,
            partialVisibilityGutter: props.partialVisible && 30
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464
            },
            items: numberOfSlidesForTablet || numberOfSlides,
            partialVisibilityGutter: props.partialVisible && 30
          }
        }}
        {...props}
        className={classNames(styles.root, 'w-full', props.className)}
      >
        {children}
      </Carousel>
    </div>
  );
};
