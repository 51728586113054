import { useCart, useGTMDataLayer, useZipcode } from '@hooks';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

// import TrashIcon from '@assets/icons/trash-small.svg';
// import PlusIcon from '@assets/icons/plus-small.svg';
import { Button } from '@components/common/Button';
import { ZipcodeModal } from '@components/common/Header/ZipcodeModal';
import { iProduct } from '@store/cart';

const BuyButton = ({ product }) => {
  const {
    cart,
    fetchCart,
    handleAddProductToCart,
    // handleUpdateCartItemQuantity,
    // handleRemoveProductFromCart,
    setProductPageAvailabilityError
  } = useCart();
  const { zipcode } = useZipcode();
  const { pushToDataLayer } = useGTMDataLayer();
  const [zipcodeIsVisible, setZipcodeIsVisible] = useState(false);

  const productOnCart = useMemo(() => {
    const productFoundOnCart = cart?.items?.find(
      itemInCart => itemInCart?.product?.id === product?.id
    );

    if (productFoundOnCart) {
      return productFoundOnCart;
    }

    return {};
  }, [cart?.items, product?.id]) as iProduct;

  const {
    // register,
    // handleSubmit,
    // watch,
    setValue
  } = useForm({
    defaultValues: { quantity: productOnCart?.quantity }
  });

  // const { quantity } = watch();

  useEffect(() => {
    if (productOnCart) setValue('quantity', productOnCart.quantity);
  }, [productOnCart, setValue]);

  const handleOnCompleted = useCallback(
    async ({ quantity, isSignature, data }) => {
      if (
        isEmpty(data?.addSimpleProductsToCart) &&
        isEmpty(data?.addConfigurableProductsToCart)
      ) {
        setProductPageAvailabilityError(true);
        return;
      }
      toast.success('Produto adicionado no carrinho com sucesso', {
        id: 'addProductToCart'
      });

      const newQuantity = productOnCart?.quantity
        ? productOnCart.quantity + quantity
        : quantity;

      pushToDataLayer('addToCart', {
        ...product,
        is_subscription: isSignature,
        is_configurable: false,
        quantity: newQuantity,
        value:
          newQuantity * product?.price_range?.minimum_price?.final_price?.value,
        coupon: cart?.applied_coupons?.[0]?.code,
        discount: cart?.prices?.discounts?.[0]?.amount?.value
      });

      setProductPageAvailabilityError(false);
      await fetchCart();
    },
    [
      productOnCart.quantity,
      pushToDataLayer,
      product,
      cart?.applied_coupons,
      cart?.prices?.discounts,
      setProductPageAvailabilityError,
      fetchCart
    ]
  );

  // const handleDeleteProduct = useCallback(async () => {
  //   await handleRemoveProductFromCart(productOnCart?.id);
  //   pushToDataLayer('removeFromCart', {
  //     ...product,
  //     parent_sku: null,
  //     is_configurable: false,
  //     quantity: quantity,
  //     value: quantity * product?.price_range?.minimum_price?.final_price?.value
  //   });
  //   toast.success('Produto removido com sucesso');
  // }, [
  //   handleRemoveProductFromCart,
  //   product,
  //   productOnCart?.id,
  //   pushToDataLayer,
  //   quantity
  // ]);

  // const handleUpdateProduct = useCallback(async () => {
  //   await handleUpdateCartItemQuantity({
  //     id: productOnCart?.id,
  //     quantity: Number(quantity)
  //   });

  //   pushToDataLayer('addToCart', {
  //     ...product,
  //     is_subscription: false,
  //     is_configurable: false,
  //     quantity: quantity,
  //     value: quantity * product?.price_range?.minimum_price?.final_price?.value
  //   });
  //   toast.success('Produto atualizado com sucesso');
  // }, [
  //   handleUpdateCartItemQuantity,
  //   product,
  //   productOnCart?.id,
  //   pushToDataLayer,
  //   quantity
  // ]);

  const handleAddProduct = useCallback(async () => {
    await handleAddProductToCart({
      quantity: 1,
      product: product,
      onCompleted: ({ data }) =>
        handleOnCompleted({
          // @TODO - Signature to be added on future tasks
          quantity: 1,
          isSignature: false,
          data
        })
    });
    setValue('quantity', 1);
  }, [handleAddProductToCart, handleOnCompleted, product, setValue]);

  return (
    <div className="flex w-full mt-auto">
      {/* {productOnCart?.quantity > 0 ? (
        <form
          className="flex w-full h-[32px] justify-between items-center"
          onSubmit={handleSubmit(handleUpdateProduct)}
        >
          <button
            type="button"
            onClick={handleDeleteProduct}
            className="bg-primary-medium rounded"
          >
            <TrashIcon className="text-white-lightest m-2" />
          </button>

          <input
            type="number"
            id="quantity"
            min={1}
            className="text-center mx-2 w-full h-full rounded border border-gray-light bg-white focus:bg-white text-gray-darkest"
            {...register('quantity')}
          />

          <button type="submit" className="bg-primary-medium rounded">
            <PlusIcon className="text-white-lightest m-2" />
          </button>
        </form>
      ) : ( */}
      <Button
        className="w-full h-[32px] gtm_comprar_button"
        onClick={() => {
          if (zipcode) {
            handleAddProduct();
          } else {
            setZipcodeIsVisible(true);
          }
        }}
      >
        Comprar
      </Button>
      {/* )} */}

      {zipcodeIsVisible && (
        <ZipcodeModal
          isVisible={zipcodeIsVisible}
          handleToggle={finalPostcode => {
            setZipcodeIsVisible(false);
            if (finalPostcode) handleAddProduct();
          }}
          onCloseModal={() => {
            setZipcodeIsVisible(false);
          }}
        />
      )}
    </div>
  );
};

export default BuyButton;
