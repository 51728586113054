import { useAuth, useRecurrence } from '@hooks';
import Image from 'next/image';
import Link from 'next/link';
import { Suspense } from 'react';
import { ContentWrapper } from '../ContentWrapper';
import { DynamicImage } from '../DynamicImage';
import { SignedInLink } from '../SignedInLink';
import { SubscribeEmailToNewsletter } from '../SubscribeEmailToNewsletter';

const aboutLinks = new Map()
  .set('Sobre nós', '')
  .set('Compra programada', '/compra-programada/l')
  .set('Quem somos', '/quem-somos/l')
  .set('Atendimento', '/atendimento/l');

const accountLinks = new Map()
  .set('Políticas', '')
  .set('Trocas e devoluções', '/atendimento')
  .set('Política de privacidade', '/politica-de-privacidade/l')
  .set('Política de cookies', '/politica-de-cookies/l');

const helpLinks = new Map()
  .set('Produtos', '')
  .set('Adultos', '/adultos/c')
  .set('Infantil', '/infantil/c')
  .set('Promoções', '/promocoes/c')
  .set('Black Friday', '/black-friday/c');

export const FOOTER_LINKS = new Map()
  .set('about', aboutLinks)
  .set('account', accountLinks)
  .set('help', helpLinks);

const LINKS_MODEL = {
  links: FOOTER_LINKS
};

export const Footer = ({ links = LINKS_MODEL.links }) => {
  const {
    recurrenceConfig: { enabled }
  } = useRecurrence();

  const { isSignedIn } = useAuth();

  const linkGroups = Array.from(links, ([groupKey, linkProps]) => {
    const linkElements = Array.from(linkProps, ([text, path]: any) => {
      const itemKey = `text: ${text} path:${path}`;
      const child = path ? (
        <Link href={path} className="text-gray-dark hover:underline">
          {text}
        </Link>
      ) : (
        <span className="text-[18px] font-bold text-gray-dark">{text}</span>
      );

      return (
        <li
          key={itemKey}
          className="border-b-2 border-gray-lightest w-full last:border-none md:border-none"
        >
          <div className="p-4 md:p-0 md:mb-6 w-full">{child}</div>
        </li>
      );
    });

    return (
      <ul
        key={groupKey}
        className="w-auto border-b-4 border-gray-lightest last:border-b-0 md:border-none"
      >
        {linkElements}
      </ul>
    );
  });

  const accountLinks = (
    <Suspense>
      <ul className="w-full border-b-4 border-gray-lightest md:flex md:flex-row md:text-base md:border-none md:-ml-2">
        <li className="text-sm md:text-md font-bold underline p-4 text-gray-dark">
          <Link prefetch={false} href={isSignedIn ? '#top' : '/sign-in'}>
            Faça seu login
          </Link>
        </li>
        <li className="text-sm md:text-md font-bold underline p-4 text-gray-dark">
          <Link prefetch={false} href={isSignedIn ? '#top' : '/sign-in'}>
            Cadastre-se
          </Link>
        </li>

        <li className="text-sm md:text-md font-bold underline p-4 text-gray-dark">
          <SignedInLink
            prefetch={false}
            href={isSignedIn ? '/orders' : '/sign-in'}
          >
            Meus pedidos
          </SignedInLink>
        </li>

        {enabled != '0' && (
          <li className="text-sm md:text-md font-bold underline p-4 text-gray-dark">
            <SignedInLink
              prefetch={false}
              href={isSignedIn ? '/signature' : '/sign-in'}
            >
              Compras programadas
            </SignedInLink>
          </li>
        )}
      </ul>
    </Suspense>
  );

  return (
    <footer
      className="border-primary-medium md:border-b-4"
      id="account-links-footer"
    >
      <ContentWrapper
        className="my-0 md:my-3 border-t border-b border-gray-light md:border-b-8 md:border-gray-lightest"
        wrapperClassName="h-[80px] flex items-center"
      >
        <div className="flex justify-between mx-auto w-full p-5  md:py-0 md:px-12">
          <div className="flex flex-row w-full md:w-min items-center justify-end md:justify-start">
            <div className="m-auto flex flex-row gap-6 justify-center items-center gtm-redesSociais">
              <a
                href="https://instagram.com/mundodanone"
                target="_blank"
                rel="noreferrer"
                className="h-[32px] w-8"
              >
                <Image
                  src="/assets/icons/instagram.svg"
                  alt="Instagram"
                  width={32}
                  height={32}
                />
              </a>

              <a
                href="https://www.facebook.com/mundodanone"
                target="_blank"
                rel="noreferrer"
                className="h-[32px] w-8"
              >
                <Image
                  src="/assets/icons/facebook.svg"
                  alt="Facebook"
                  width={32}
                  height={32}
                />
              </a>

              <a
                href="https://wa.me/551130958482?text=Ol%C3%A1,%20como%20posso%20comprar%20pelo%20WhatsApp?"
                target="_blank"
                rel="noreferrer"
                className="h-[32px] w-8"
              >
                <Image
                  src="/assets/icons/whatsapp.svg"
                  alt="Whatsapp"
                  width={32}
                  height={32}
                />
              </a>

              <a
                href="https://www.tiktok.com/@mundodanone"
                target="_blank"
                rel="noreferrer"
                className="h-[32px] w-[32px] relative"
              >
                <Image
                  src="/assets/icons/tiktok.svg"
                  alt="TikTok"
                  width={32}
                  height={32}
                />
              </a>
            </div>
          </div>
          <div className="relative w-5/12 hidden md:block">
            <Image
              src="/assets/icons/credicardbrands.svg"
              alt="Cartões de crédito"
              fill
              className="keep-color"
            />
          </div>
        </div>
      </ContentWrapper>

      <ContentWrapper className="my-0 md:my-3">
        <div className="flex flex-col w-full md:flex-row md:py-6 md:px-8 md:items-stretch">
          <div className="border-b-4 p-4 md:py-0 border-gray-lightest md:border-none md:w-9/12">
            <div id="account-links-footer">
              <h3 className="text-[18px] text-gray-dark font-bold mb-2">
                Fique por dentro
              </h3>

              <p className="mb-3 text-gray-dark text-md">
                Receba nossas novidades e promoções por e-mail
              </p>
              <div className="md:min-w-[320px] md:w-full">
                <SubscribeEmailToNewsletter
                  textSuccessColor={'text-success-dark'}
                  originId="footer"
                />
              </div>
              <p className="mt-4 text-gray-dark text-xs">
                Ao se cadastrar você concorda com nossa{' '}
                <Link
                  href="/politica-de-privacidade/l"
                  className="text-gray-dark hover:underline"
                >
                  <b className="underline">Política de privacidade</b>
                </Link>{' '}
                e{' '}
                <Link
                  href="/politica-de-cookies/l"
                  className="text-gray-dark hover:underline"
                >
                  <b className="underline">Política de cookies</b>
                </Link>
                , além de que tem idade igual ou superior a 18 anos.
              </p>
            </div>
          </div>

          <div className="flex flex-col gap-10 md:flex-row md:order-first md:w-full gtm-menuFooter">
            {linkGroups}
            <div className="w-full md:hidden">{accountLinks}</div>
          </div>

          <div className="flex flex-row justify-center items-center p-4 md:p-0 md:w-6/12 sm:mb-0">
            <div className="w-1/3 h-[128px] relative md:hidden">
              <Image
                src="/assets/icons/creditcardbrandsmobile.svg"
                alt="Cartões de crédito"
                fill
              />
            </div>

            <div className="py-5 border-l border-gray-light ml-6 pl-6 w-5/12 flex flex-col md:flex-row items-center md:items-start justify-center md:border-none md:w-full md:h-full">
              <div className="relative flex-shrink-0 h-[80px] w-[80px] relative flex items-center">
                <Image
                  src="/assets/icons/selo.svg"
                  alt="Selo Empresa B"
                  width={80}
                  height={80}
                />
              </div>
              <div className="relative justify-center mt-4 md:mt-0 sm:ml-4 w-full h-[48px] md:h-[80px] flex md:items-center">
                <DynamicImage
                  mobileSrc="/assets/icons/RA1000.png"
                  desktopSrc="/assets/icons/RA1000.png"
                  mobileProps={{
                    width: 134,
                    height: 48
                  }}
                  desktopProps={{
                    width: 142,
                    height: 80
                  }}
                  alt="Selo reclame aqui"
                  className="object-contain"
                />
              </div>
            </div>
          </div>
        </div>
      </ContentWrapper>

      <ContentWrapper className="my-0 md:my-3 border-t border-gray-lightest md:border-t-8">
        <div className="w-full p-5 text-gray-medium text-sm flex flex-row md:items-center md:px-10">
          <div className="hidden w-1/2 md:block">{accountLinks}</div>
          <p className="mx-auto w-full md:ml-auto md:w-1/2 md:whitespace-wrap md:line-clamp-2 text-center text-sm text-gray-dark">
            2024 Magento Enterprise Edition. All rights Reserved. Razão Social:
            Danone Ltda. - CNPJ: 23.643.315/0001-52
          </p>
        </div>
      </ContentWrapper>
    </footer>
  );
};
