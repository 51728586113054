import { formatCurrency } from '@utils';
import classNames from 'classnames';

interface TierPriceBuyProps {
  valueDiscount: number;
  quantity: number;
  value: number | string;
  size: string;
  className?: string;
}

export const TierPriceBuy = ({
  quantity,
  value,
  size = 'small',
  className = 'flex'
}: TierPriceBuyProps) => {
  const sizeClassName = {
    'text-xs': size === 'medium',
    'text-[10px]': size === 'small',
    'text-[16px]': size === 'large'
  };

  return quantity ? (
    <div
      className={classNames(
        'flex-row justify-between mb-1 mh-[20px] lg:max-w-none',
        className
      )}
    >
      <div
        className={classNames(
          ' text-gray-dark flex items-center content-between flex-1 rounded-[4px]'
        )}
      >
        <span className={classNames('lg:py-1 xl:py-0', sizeClassName)}>
          {quantity} por{' '}
          <span className="font-bold text-primary-medium">
            {typeof value === 'number' ? formatCurrency(value) : value}
          </span>{' '}
          cada
        </span>
      </div>
    </div>
  ) : null;
};
