import classNames from 'classnames';
import { FilterList } from './FilterList';

const DISABLED_FILTERS = ['price'];

export const FilterBlock = ({
  filterApi,
  filterState,
  group,
  items,
  name,
  applyFilters
}) => {
  return (
    <li
      className={classNames({
        hidden: DISABLED_FILTERS.includes(group),
        '-mb-4': group !== 'category_id',
        'order-first': group === 'category_id'
      })}
    >
      <span
        className={classNames('flex text-base', {
          'text-sm mb-2 text-gray-dark': group === 'category_id',
          'mt-8 mb-5 text-gray-darkest': group !== 'category_id'
        })}
      >
        {/* <FormattedMessage
          id={`filterBlock.${group}Title`}
          defaultMessage={name}
        /> */}
        {name}
        {group !== 'category_id' && ':'}
      </span>

      <form>
        <FilterList
          filterApi={filterApi}
          filterState={filterState}
          group={group}
          items={items}
          applyFilters={applyFilters}
        />
      </form>
    </li>
  );
};
