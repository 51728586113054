import winston from 'winston';
// import { CustomNewRelicTransportError } from './customNewrelicTransportError';

const { format, transports } = winston;
function getLogger() {
  const logger = winston.createLogger({
    level: 'debug',
    format: format.combine(format.splat(), format.simple()),
    transports: [new transports.Console()]
  });

  return logger;
}

export default getLogger();
