import ReactSelect, { Props } from 'react-select';
import styles from './select.module.scss';

export const Select = (props: Props) => (
  <ReactSelect
    classNamePrefix="select"
    className={styles.selectWrapper}
    placeholder="Selecione"
    {...props}
  />
);
