import { useContext } from 'react';
import { StoreContext } from '@contexts/store';

export const useStoreCode = () => {
  const context = useContext(StoreContext);

  if (!context) throw new Error('Store Context must be withing Store provider');

  return context;
};
