import { useQuery } from '@apollo/client';
import Head from 'next/head';
import { RichContent } from '@common';
import { GetCmsPageDocument } from '@generated/graphql';
import { addApolloState, initializeApollo } from '@client/service';

interface CMSPageProps {
  id: number;
  richContentClassName?: string;
}

export const CMSPage = ({ id, richContentClassName }: CMSPageProps) => {
  const {
    data: { cmsPage }
  } = useQuery(GetCmsPageDocument, { variables: { id } });
  return (
    <>
      <Head>
        <title>{cmsPage.meta_title}</title>
        <meta name="description" content={cmsPage.meta_description} />
        <meta name="keywords" content={cmsPage.meta_keywords} />
      </Head>
      <RichContent className={richContentClassName} html={cmsPage.content} />
    </>
  );
};

export const prefetchCMSPage = (id: number) => async () => {
  const apolloClient = initializeApollo();
  await apolloClient.query({
    query: GetCmsPageDocument,
    variables: { id }
  });

  return addApolloState(apolloClient, {
    props: {},
    revalidate: 60 * 5
  });
};
