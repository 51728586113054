'use client';

/**
 * Scroll to the next element on the page
 * @param id - id of the element we want to skip on scroll
 * @param fallbackValues - fallback values for the scroll, in case the element is not found
 * @param fallbackValues.offsetTop - fallback value for the offsetTop
 * @param fallbackValues.offsetHeight - fallback value for the offsetHeight
 * @param fallbackValues.skipElementHeight - if true, the scroll will skip the element height
 * @returns void
 */
export const scrollToNextElement = (
  id: string,
  fallbackValues: {
    offsetTop?: number;
    offsetHeight?: number;
    skipElementHeight?: boolean;
  }
) => {
  const element = document.getElementById(id);
  const { skipElementHeight } = fallbackValues;

  if (!element) {
    const { offsetTop = 200, offsetHeight = 50 } = fallbackValues;
    const top = skipElementHeight ? offsetTop : offsetTop + offsetHeight;
    window.scrollTo({ top });
    return;
  }

  const { offsetTop, offsetHeight } = element;
  const top = skipElementHeight ? offsetTop : offsetTop + offsetHeight;
  window.scrollTo({ top });
};
