import { useMemo } from 'react';
import { FilterItem } from './FilterItem';
import { first } from 'lodash';
import { Select } from '@common';

const labels = new WeakMap();

export const FilterList = ({
  filterApi,
  filterState,
  group,
  items,
  applyFilters
}) => {
  // memoize item creation
  // search value is not referenced, so this array is stable
  const itemOptions = useMemo(() => {
    const options = items.map(item => {
      const { title, value } = item;

      return {
        value,
        label: title
      };
    });

    return options;
  }, [items]);

  const itemElements = useMemo(
    () =>
      items.map(item => {
        const { title, value } = item;
        const key = `item-${group}-${value}`;

        // create an element for each item
        const element = (
          <li key={key}>
            <FilterItem
              filterApi={filterApi}
              filterState={filterState}
              group={group}
              item={item}
              applyFilters={applyFilters}
            />
          </li>
        );

        // associate each element with its normalized title
        // titles are not unique, so use the element as the key
        labels.set(element, title.toUpperCase() || '');

        return element;
      }),
    [applyFilters, filterApi, filterState, group, items]
  );

  const { selectItem } = filterApi;

  return (
    <>
      {group !== 'category_id' ? (
        <ul>{itemElements}</ul>
      ) : (
        <Select
          isSearchable={false}
          name={group}
          defaultValue={
            filterState && filterState.values().next()?.value?.value
              ? {
                  value: first(filterState.values().next()?.value?.value),
                  label: filterState.values().next()?.value?.title
                }
              : undefined
          }
          onChange={({ label, value }) => {
            selectItem({
              group,
              item: {
                title: label,
                value: value
              }
            });
            applyFilters();
          }}
          options={itemOptions}
        />
      )}
    </>
  );
};
