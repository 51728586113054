import { getImageProps, ImageProps } from 'next/image';

type CommonImageProps = {
  width?: number;
  height?: number;
  quality?: number;
};

export type ArtDirectionImageProps = Omit<ImageProps, 'src'> & {
  desktopSrc: string;
  mobileSrc: string;
  mobileProps?: CommonImageProps;
  desktopProps?: CommonImageProps;
};

export const DynamicImage = ({
  desktopSrc,
  mobileSrc,
  mobileProps,
  desktopProps,
  alt,
  ...props
}: ArtDirectionImageProps) => {
  const common = { alt, ...props };
  const {
    props: { srcSet: desktop }
  } = getImageProps({
    ...common,
    width: 1440,
    height: 875,
    quality: 80,
    ...desktopProps,
    src: desktopSrc
  });
  const {
    props: { srcSet: mobile, ...rest }
  } = getImageProps({
    ...common,
    width: 750,
    height: 1334,
    quality: 60,
    ...mobileProps,
    src: mobileSrc
  });

  return (
    <picture>
      <source media="(max-width: 1023px)" srcSet={mobile} />
      <source media="(min-width: 1024px)" srcSet={desktop} />
      <img {...rest} style={{ width: '100%', height: 'auto' }} alt={alt} />
    </picture>
  );
};
