import classNames from 'classnames';
import { ButtonHTMLAttributes, ReactNode } from 'react';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  iconLeft?: any;
  iconRight?: any;
  variant?: string | undefined;
  className?: string | undefined;
  disabled?: boolean | undefined;
  rounded?: boolean | undefined;
  children: ReactNode;
}

export const Button = ({
  iconLeft,
  iconRight,
  variant = 'primary',
  className,
  rounded = true,
  children,
  ...rest
}: ButtonProps) => {
  const defaultClassName = classNames(
    'px-6 py-3 items-center justify-center uppercase font-bold text-sm',
    {
      rounded: rounded
    },
    className
  );

  const primaryClass = classNames(
    defaultClassName,
    'flex flex-row bg-primary-medium text-white hover:bg-primary-dark disabled:bg-primary-light'
  );
  const otherClass = classNames(
    defaultClassName,
    'flex flex-row bg-white text-primary-medium disabled:text-primary-medium border-2 border-solid border-primary-medium hover:text-primary-dark hover:border-primary-dark disabled:border-primary-light disabled:cursor-not-allowed'
  );

  const finalClassName = variant === 'primary' ? primaryClass : otherClass;

  return (
    <button {...rest} className={finalClassName} type={rest?.type || 'button'}>
      {iconLeft ? iconLeft : null}
      {children}
      {iconRight ? iconRight : null}
    </button>
  );
};
