import Head from 'next/head';
import { useCanonicalURL } from '@hooks';

interface MetaTagsProps {
  meta: {
    title: string | null;
    meta_title: string | null;
    meta_description: string | null;
    meta_keywords: string | null;
  };
}

export const MetaTags = ({ meta }: MetaTagsProps) => {
  /**
   * If not in prod environment
   * adds robots meta tag for every page
   */
  const showNoIndex = process.env.NEXT_PUBLIC_ENVINROMENT !== 'prod';

  const canonicalUrl = useCanonicalURL();

  return (
    <Head>
      <title>{meta.meta_title || meta.title}</title>
      {showNoIndex && <meta name="robots" content="noindex,nofollow" />}
      {meta?.meta_title && <meta name="title" content={meta.meta_title} />}
      {meta?.meta_description && (
        <meta name="description" content={meta.meta_description} />
      )}
      {meta?.meta_keywords && (
        <meta name="keywords" content={meta.meta_keywords} />
      )}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
    </Head>
  );
};
