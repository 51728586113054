import Image, { ImageProps } from 'next/image';

// src, width, quality
// function customImageLoader({ src }) {
//   return `${src}`;
// }

export const CustomImage = (props: ImageProps) => {
  const { alt, ...otherProps } = props;

  return (
    <Image
      alt={alt}
      objectFit="contain"
      // loader={customImageLoader}
      {...otherProps}
    />
  );
};
