interface PriceFormat {
  value: number;
  currency?: string;
  locale?: string;
}

export const priceFormat = ({
  value,
  currency = 'BRL',
  locale = 'pt-BR'
}: PriceFormat) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(value);
};

/**
 * Formats a number value into a Brazilian currency string.
 * @param {number} value the value to be formatted
 * @returns {string} the formatted string as Brazilian currency.
 */
export const formatCurrency = (value: number) => {
  return new Intl.NumberFormat('pt-BR', {
    currency: 'BRL',
    style: 'currency'
  }).format(value);
};
