import { first } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

export const configuredVariant = (configured_options, product) => {
  if (!configured_options || !product.variants) return;
  const optionUids = configured_options
    .map(option => {
      return Buffer.from(
        `configurable/${option.id}/${option.value_id}`
      ).toString('base64');
    })
    .sort()
    .toString();

  return product.variants
    .map(variant => {
      const variantUids = variant.attributes
        .map(attribute => attribute.uid)
        .sort()
        .toString();
      return variantUids === optionUids && variant.product;
    })
    .filter(Boolean)[0];
};

export const useItem = props => {
  const { id, handleRemoveItem } = props;

  const [isDeleting, setIsDeleting] = useState(false);

  const removeItem = useCallback(async () => {
    setIsDeleting(true);
    await handleRemoveItem(id);
  }, [handleRemoveItem, id]);

  return {
    isDeleting,
    removeItem
  };
};

export const useStockStatusMessage = props => {
  const { cartItems } = props;

  const hasOutOfStockItem = useMemo(() => {
    if (cartItems) {
      const isOutOfStock = cartItems.find(cartItem => {
        const { product, configurable_options } = cartItem;
        const selectedFlavor = first(configurable_options) as any;

        const productFiltered = () => {
          if (product.__typename === 'ConfigurableProduct' && selectedFlavor) {
            const foundFlavor = product.variants.find(
              ({ product: variant }) =>
                variant.custom_flavor === selectedFlavor.value_id
            );

            if (foundFlavor) return foundFlavor?.product;
          }

          return product;
        };

        const { stock_status: stockStatus } = productFiltered();

        return stockStatus === 'OUT_OF_STOCK';
      });

      return !!isOutOfStock;
    }
  }, [cartItems]);

  return { hasOutOfStockItem };
};
