/**
 * Formats a string into a Brazilian style Postcode.
 * @param {string} value Postcode string value `regex: /\d{8}/`
 * @returns Postcode formatted as Brazilian 'CEP'
 */
export const formatPostcode = value => {
  if (value) {
    return value.replace('-', '').replace(/([0-9]{5})([0-9]{3})/, '$1-$2');
  }

  return '';
};

/**
 * Checks if a given Postcode is a Brazilian valid one.
 * @param {boolean} value Brazilian style Postcode
 * @returns Whether it is or not a valid Brazilian style Postcode
 */
export function isValidPostcode(value) {
  return /^\d{5}-?\d{3}$/.test(value);
}
