interface StockProps {
  isOutOfStock: boolean | undefined;
}

export const Stock = ({ isOutOfStock }: StockProps) => {
  return (
    <span
      className={`${
        isOutOfStock
          ? 'bg-error-lightest text-error-medium'
          : 'bg-primary-lightest text-primary-medium'
      } px-2 py-1 rounded-full text-sm font-bold whitespace-nowrap`}
    >
      {isOutOfStock ? 'Fora de Estoque' : 'Em estoque'}
    </span>
  );
};
