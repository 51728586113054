import { RecurrenceContext, iRecurrenceContext } from '@contexts/recurrence';
import { useContext } from 'react';

export const useRecurrence = (): iRecurrenceContext => {
  const context = useContext(RecurrenceContext);

  if (!context) {
    throw new Error('Recurrence Context must be within Provider');
  }

  return context;
};
