import { gql, useMutation, useQuery } from '@apollo/client';
import { useCart, useGTMDataLayer, useStoreSwitcher, useZipcode } from '@hooks';
import { cartIdAtom } from '@store/cart';
import { formatPostcode, Persistence } from '@utils';
import { atom, useAtom, useSetAtom } from 'jotai';
import { first, isEmpty, orderBy } from 'lodash';
import { useRouter, useSearchParams } from 'next/navigation';
import { useCallback } from 'react';
import toast from 'react-hot-toast';

export type iAuthParams = { email: string; password: string };

export interface iUser {
  addresses: { postcode: string; default_shipping: boolean }[];
  email: string;
  firstname: string;
  gender: number;
  id: string;
  is_subscribed: boolean;
  lastname: string;
  token: string;
}
export interface IAuthContext {
  isSignedIn: boolean;
  user: iUser;
  setUserToken: (token: string) => Promise<iUser>;
  handleSignIn(authParams: iAuthParams): Promise<void>;
  handleSignOut(isLac?: boolean): void;
  handleAuthError(err: { message?: string }): void;
}

export const SIGN_IN = gql`
  mutation SignIn($email: String!, $password: String!) {
    generateCustomerToken(email: $email, password: $password) {
      token
    }
  }
`;

export const GET_CUSTOMER = gql`
  query GetCustomerAfterSignIn {
    customer {
      id
      email
      firstname
      lastname
      is_subscribed
      gender
      addresses {
        postcode
        default_shipping
      }
    }
  }
`;

export const MERGE_CARTS = gql`
  mutation MergeCartsAfterAccountCreation($sourceCartId: String!) {
    mergeCarts(source_cart_id: $sourceCartId) @connection(key: "mergeCarts") {
      id
      items {
        id
      }
    }
  }
`;

export const userAtom = atom<iUser>({} as iUser);
export const isSignedInAtom = atom(
  Persistence.getItem('signin_token') != null,
  (get, set, isSignedIn: boolean) => {
    set(isSignedInAtom, isSignedIn);
  }
);

export const useAuth = () => {
  const token = Persistence.getItem('signin_token');
  const [isSignedIn, setSignedIn] = useAtom(isSignedInAtom);
  const [user, setUser] = useAtom<iUser>(userAtom);
  const setCartId = useSetAtom(cartIdAtom);
  const [signIn] = useMutation(SIGN_IN);
  const [mergeCarts] = useMutation(MERGE_CARTS);
  const { refetch: getCustomer } = useQuery(GET_CUSTOMER, {
    skip: !token || Object.keys(user).length > 0,
    fetchPolicy: 'no-cache',
    onCompleted: data => setUser(data?.customer)
  });

  const {
    createCart,
    fetchCart,
    removeCart,
    cartId,
    handleLoadShippingMethods
  } = useCart();
  const { zipcode, handleSetZipcode } = useZipcode();
  const { handleSwitchStoreByPostcode } = useStoreSwitcher();
  const { pushToDataLayer } = useGTMDataLayer();
  const router = useRouter();
  const searchParams = useSearchParams();
  const { redirect } = Object.fromEntries(searchParams.entries());

  const setUserToken = useCallback(
    async (token: string) => {
      if (token !== undefined) {
        Persistence.setItem('signin_token', token);
        setSignedIn(true);
        const { data } = await getCustomer({
          context: { headers: { Authorization: `Bearer ${token}` } }
        });

        return data?.customer;
      }
    },
    [getCustomer, setSignedIn]
  );

  const handleSignIn = useCallback(
    async ({ email, password }: iAuthParams) => {
      try {
        const { data: signInData } = await signIn({
          variables: { email, password }
        });
        const fetchedUserData = await setUserToken(
          signInData?.generateCustomerToken.token
        );

        const sourceCartId = cartId;
        await removeCart();

        if (!isEmpty(fetchedUserData?.addresses) && !zipcode) {
          const addresses = fetchedUserData?.addresses;
          const address =
            addresses.find(address => address.default_shipping) ||
            first(orderBy(addresses, ['id'], ['desc']));

          if (address) {
            await handleSwitchStoreByPostcode(address?.postcode);
            handleSetZipcode(address.postcode);
            Persistence.setItem('postcode', formatPostcode(address?.postcode));
          }
        }

        if (sourceCartId) {
          const { data: mergedCarts } = await mergeCarts({
            variables: {
              sourceCartId
            },
            errorPolicy: 'all',
            context: {
              headers: {
                authorization: `Bearer ${signInData?.generateCustomerToken.token}`
              }
            }
          });

          if (mergedCarts) {
            const destinationCartId = mergedCarts?.mergeCarts?.id;
            setCartId(destinationCartId);
            await Persistence.setItem('@cart/id', destinationCartId);
            await fetchCart({ cartId: destinationCartId });
            await handleLoadShippingMethods({
              newCartId: destinationCartId
            });
          }
        } else {
          const newCartId = await createCart();
          setCartId(newCartId);
          await fetchCart({ cartId: newCartId });
          await handleLoadShippingMethods({
            newCartId
          });
        }

        pushToDataLayer('login', { method: 'site', status: true });

        const isExternal = /^(https|http):\/\/[\D|\d]*/.test(
          redirect as string
        );

        if (redirect && isExternal) {
          router.push('/404');
        }

        if (redirect && !isExternal) {
          router.push(redirect as string);
        }

        if (!redirect) {
          router.push('/');
        }
      } catch (err) {
        pushToDataLayer('login', { method: 'site', status: false });
        toast.error(err.message);
      }
    },
    [
      cartId,
      createCart,
      fetchCart,
      handleLoadShippingMethods,
      handleSetZipcode,
      handleSwitchStoreByPostcode,
      mergeCarts,
      pushToDataLayer,
      redirect,
      removeCart,
      router,
      setUserToken,
      signIn,
      zipcode
    ]
  );

  const handleSignOut = useCallback(
    async (isLac?: boolean) => {
      try {
        await removeCart();
        localStorage.clear();
        setUser({} as iUser);
        setSignedIn(false);
        handleSetZipcode(null);
        pushToDataLayer('logout', { method: 'site', status: true });
        if (!isLac) {
          router.push('/');
        }
      } catch (e) {
        pushToDataLayer('logout', { method: 'site', status: false });
      }
    },
    [handleSetZipcode, pushToDataLayer, removeCart, router]
  );

  const handleAuthError = useCallback(
    err => {
      if (err?.message === 'O cliente atual não está autorizado.') {
        removeCart();
        localStorage.clear();
        setUser({} as iUser);
        setSignedIn(false);
        router.push('/sign-in');
      }
    },
    [removeCart, router]
  );

  return {
    user,
    setUserToken,
    isSignedIn,
    handleSignIn,
    handleSignOut,
    handleAuthError
  };
};
