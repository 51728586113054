import Image from 'next/image';

interface TagsProps {
  tags: {
    image: string;
    name: string;
    position: string;
  }[];
  narrow: boolean;
}

const positionStyles = {
  'top-right': 'absolute top-0 right-0 md-right-7',
  'top-left': 'absolute top-0 left-0 md-left-7',
  'bottom-right': 'absolute top-[75px] right-0 md-right-7',
  'bottom-left': 'absolute top-[75px] left-0 md-left-7'
};

export const Tags = ({ tags, narrow }: TagsProps) => {
  return (
    <>
      {tags?.length
        ? tags?.map((tag, i) => (
            <span key={tag.name + i} className={positionStyles[tag.position]}>
              <Image
                width={narrow ? 40 : 50}
                height={narrow ? 40 : 50}
                src={tag.image}
                alt={tag.name}
              />
            </span>
          ))
        : null}
    </>
  );
};
