import { ButtonHTMLAttributes, ReactNode } from 'react';

interface QuantityButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
}

const defaultStyle = `
  flex
  w-[32px]
  h-[32px]
  justify-center
  items-center
  border
  rounded
  text-xl
`;

const enabledStyle = `
  bg-primary-medium
  border-primary-medium
  text-white-light 
  border-2
`;

const disabledStyle = `
  bg-gray-light
  text-white-light
  border-gray-light
`;

export const QuantityButton = ({
  children,
  disabled,
  ...rest
}: QuantityButtonProps) => {
  const btnStyle = disabled ? disabledStyle : enabledStyle;
  return (
    <button
      {...rest}
      type="button"
      className={`${defaultStyle} ${btnStyle}`}
      disabled={disabled}
    >
      <span className="h-full w-full flex justify-center items-center">
        {children}
      </span>
    </button>
  );
};
