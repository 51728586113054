import { iZipcodeContext, ZipcodeContext } from '@contexts/zipcode';
import { useContext } from 'react';

export const useZipcode = (): iZipcodeContext => {
  const context = useContext(ZipcodeContext);

  if (!context) {
    throw new Error('Zipcode Provider must be within Context');
  }

  return context;
};
