import InfoIcon from '@assets/icons/infoIcon.svg';

export interface AlertProps {
  text: string;
  highlight?: string;
}

export function Alert({ text, highlight }: AlertProps) {
  return (
    <div className="flex flex-col p-3 lg:p4">
      <div className="bg-primary-lightest p-2 mt-3 rounded">
        <div className="flex flex-row align-middle">
          <InfoIcon
            color="transparent"
            stroke="red"
            className="mr-2 min-w-6"
            alt=""
          />
          <div>
            <span className="text-secondary-darkest">
              {highlight ? highlightText(text, highlight) : text}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * Return a JSX.Element with the text highlighted
 * @param text - Text to be displayed
 * @param highlight - Text to be highlighted
 * @returns JSX.Element
 */
const highlightText = (text: string, highlight: string) => {
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return (
    <span>
      {parts.map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={index} className="text-primary-medium font-bold">
            {part}
          </span>
        ) : (
          part
        )
      )}
    </span>
  );
};
