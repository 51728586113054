export default function Tooltip({ icon, children }) {
  return (
    <span className="z-10 inline-block group relative w-[16px] h-[16px]">
      {icon}
      <div className="group-hover:inline-block hidden w-[265px] absolute left-[-120px] top-[32px] p-4 border-secondary-light border rounded bg-white before:absolute before:w-[20px] before:h-[20px] before:bg-white before:rotate-45 before:left-[116px] before:top-[-11px] before:border-secondary-light before:border-t-[1px] before:border-l-[1px]">
        {children}
      </div>
    </span>
  );
}
