import React, { useMemo, useState } from 'react';
import {
  useCart,
  useGTMDataLayer,
  useMiniCart,
  useStore,
  useStoreCode
} from '@hooks';
import { useIntl } from 'react-intl';
import {
  getBreadcrumbCategory,
  useCustomAttributes,
  useProductFullDetail
} from '../../../../hooks/useProductFullDetail';
import { gql, useMutation } from '@apollo/client';
import Link from 'next/link';
import { Price } from '@components/common/ProductCard/ProductPrice/Price';
import { Quantity } from '@common';
import {
  configuredVariant,
  getProductLink,
  Persistence,
  useItem
} from '@utils';
import DeleteIcon from '@assets/icons/trash.svg';
import styles from './productList.module.scss';
import { toast } from 'react-hot-toast';
import { possibleErrorMessages } from 'src/utils/stockErrorHandler';
import { isEmpty } from 'lodash';
import Image from 'next/image';

const UPDATE_ITEM_QUANTITY_MINIFIED = gql`
  mutation updateItemQuantity(
    $cartId: String!
    $itemId: Int!
    $quantity: Float!
  ) {
    updateCartItems(
      input: {
        cart_id: $cartId
        cart_items: [{ cart_item_id: $itemId, quantity: $quantity }]
      }
    ) @connection(key: "updateCartItems") {
      cart {
        id
        total_quantity
        prices {
          grand_total {
            currency
            value
          }
        }
        items {
          id
          quantity
          product {
            price_tiers {
              final_price {
                currency
                value
              }
            }
          }
        }
      }
    }
  }
`;

export const Item = ({
  product: itemProduct,
  id,
  quantity: initialQuantity,
  configurable_options,
  handleRemoveItem,
  prices,
  closeMiniCart
}) => {
  const { formatMessage } = useIntl();
  const { pushToDataLayer } = useGTMDataLayer();
  const { availableStores } = useStore();
  const { handleSetStore } = useStoreCode();
  const { fetchMiniCart } = useMiniCart();
  const { cart } = useCart();
  const { getProductCustomAttributes } = useCustomAttributes();

  const [quantity, setQuantity] = useState(initialQuantity);

  const product = {
    ...itemProduct,
    ...getProductCustomAttributes(itemProduct)
  };

  let configured_variant =
    configuredVariant(configurable_options, product) || product;

  configured_variant = {
    ...configured_variant,
    ...getProductCustomAttributes(configured_variant),
    category: getBreadcrumbCategory(product.categories),
    price: prices && prices.price ? prices.price : 0
  };

  const stockStatusMessage =
    configured_variant.stock_status === 'OUT_OF_STOCK'
      ? formatMessage({
          id: 'product.outOfStock',
          defaultMessage: 'Out-of-stock'
        })
      : '';

  const itemLink = getProductLink(product);

  const { isDeleting, removeItem } = useItem({
    id,
    handleRemoveItem
  });

  const { cartToken } = useCart();

  const [addItemToCart, { loading: isLoading }] = useMutation(
    UPDATE_ITEM_QUANTITY_MINIFIED
  );

  const handleUpdateItemQuantity = async quantity => {
    const cartCreated = await cartToken();
    const { errors } = await addItemToCart({
      variables: {
        cartId: cartCreated,
        quantity,
        itemId: id
      },
      errorPolicy: 'all'
    });

    if (errors && errors?.length > 0) {
      const err = errors[0];
      const fallbackStoreCode = Persistence.getItem(
        'store_vendor_fallback_code'
      );
      const isOutOfStock = possibleErrorMessages
        .map(errorMessage => err.message.includes(errorMessage))
        .some(errorMessageResult => errorMessageResult === true);

      if (isOutOfStock) {
        if (fallbackStoreCode) {
          let fallbackStore;
          availableStores.forEach(store => {
            if (store.code === fallbackStoreCode) fallbackStore = store;
          });

          if (!isEmpty(fallbackStore)) {
            handleSetStore(fallbackStore?.code);
            Persistence.setItem('store_view_code', fallbackStore?.code);
            Persistence.setItem('store_view_currency', fallbackStore.currency);
            Persistence.setItem(
              'store_view_secure_base_media_url',
              fallbackStore.secure_base_media_url
            );

            Persistence.removeItem('store_vendor_fallback_code');
            await addItemToCart({
              variables: {
                cartId: cartCreated,
                quantity,
                itemId: id
              },
              context: {
                headers: {
                  store: fallbackStore?.code
                }
              },
              onCompleted: async () => await fetchMiniCart()
            });
          }
        } else {
          toast.error('Quantidade para produto sem estoque.');
        }
      } else {
        toast.error(err.message);
      }
    }
    await fetchMiniCart();
  };

  const shouldDisable = isLoading || isDeleting;

  let imageSrc = product.small_image.url;
  if (typeof configured_variant.small_image !== 'undefined') {
    imageSrc = configured_variant.small_image.url;
  }

  const { productDetails } = useProductFullDetail({
    product
  });

  return (
    <li className="rounded shadow-lg border border-solid border-gray-light px-6 py-4">
      <div className="flex mb-3 ${classes.thumbnailNameContainer">
        <div className="relative mr-2">
          <Image
            alt={configured_variant && configured_variant.name}
            className="max-w-16 max-h-16"
            width={40}
            height={40}
            src={imageSrc}
          />
        </div>

        <div className="flex flex-1 items-start justify-between">
          <Link
            href={itemLink}
            onClick={closeMiniCart}
            className="line-clamp-2 text-gray-dark text-sm font-bold"
          >
            {configured_variant && configured_variant.name}
          </Link>

          <button
            onClick={() => {
              removeItem();
              pushToDataLayer('removeFromCart', {
                ...product,
                ...productDetails,
                parent_sku: product?.parent_sku,
                is_configurable: product?.is_configurable,
                quantity: quantity,
                value: quantity * productDetails?.price?.final_price?.value,
                discount: cart?.prices?.discounts?.[0]?.amount?.value,
                coupon: cart?.applied_coupons?.[0]?.code
              });
            }}
            type="button"
            disabled={shouldDisable}
            className="ml-auto"
          >
            <DeleteIcon className="text-gray-medium keep-color fill-transparent" />
          </button>
        </div>
      </div>

      <div className="flex justify-between items-center">
        {stockStatusMessage ? (
          <span className="bg-error-lightest text-error-medium font-bold rounded px-3 py-1.5 text-sm">
            {stockStatusMessage}
          </span>
        ) : (
          <Quantity
            value={quantity}
            onBlur={e => {
              const diffValue = Math.abs(
                initialQuantity - Number(e.target.value)
              );
              if (initialQuantity > e.target.value) {
                pushToDataLayer('removeFromCart', {
                  ...product,
                  ...productDetails,
                  parent_sku: product?.parent_sku,
                  is_configurable: product?.is_configurable,
                  quantity: diffValue,
                  value: diffValue * productDetails?.price?.final_price?.value,
                  discount: cart?.prices?.discounts?.[0]?.amount?.value,
                  coupon: cart?.applied_coupons?.[0]?.code
                });
              } else if (initialQuantity < e.target.value) {
                pushToDataLayer('addToCart', {
                  ...product,
                  ...productDetails,
                  parent_sku: product?.parent_sku,
                  is_configurable: product?.is_configurable,
                  quantity: diffValue,
                  value: diffValue * productDetails?.price?.final_price?.value,
                  coupon: cart?.applied_coupons?.[0]?.code,
                  discount: cart?.prices?.discounts?.[0]?.amount?.value
                });
              }
              handleUpdateItemQuantity(e.target.value);
            }}
            onChange={e => {
              setQuantity(e.target.value);
            }}
            handleAdd={() => {
              handleUpdateItemQuantity(Number(quantity) + 1);
              setQuantity(Number(quantity) + 1);
              pushToDataLayer('addToCart', {
                ...product,
                ...productDetails,
                parent_sku: product?.parent_sku,
                is_configurable: product?.is_configurable,
                quantity: 1,
                value: productDetails?.price?.final_price?.value,
                coupon: cart?.applied_coupons?.[0]?.code,
                discount: cart?.prices?.discounts?.[0]?.amount?.value
              });
            }}
            handleMinus={() => {
              handleUpdateItemQuantity(quantity === 0 ? 0 : quantity - 1);
              setQuantity(quantity === 0 ? 0 : quantity - 1);
              pushToDataLayer('removeFromCart', {
                ...product,
                ...productDetails,
                parent_sku: product?.parent_sku,
                is_configurable: product?.is_configurable,
                quantity: 1,
                value: productDetails?.price?.final_price?.value,
                discount: cart?.prices?.discounts?.[0]?.amount?.value,
                coupon: cart?.applied_coupons?.[0]?.code
              });
            }}
            disabled={quantity === 1}
          />
        )}

        {!isLoading && (
          <strong className="text-xl text-primary-medium">
            <Price
              currencyCode={prices.price.currency}
              value={initialQuantity * prices.price.value}
            />
          </strong>
        )}
      </div>
    </li>
  );
};

export const ProductList = props => {
  const { items, handleRemoveItem, closeMiniCart } = props;
  const cartItems = useMemo(() => {
    if (items) {
      return items.map(item => (
        <Item
          key={item.id}
          closeMiniCart={closeMiniCart}
          handleRemoveItem={handleRemoveItem}
          {...item}
        />
      ));
    }
  }, [items, closeMiniCart, handleRemoveItem]);

  return (
    <ul className={`pt-3 px-3 overflow-auto max-h-full ${styles.root}`}>
      {cartItems}
      <li className="h-10" />
    </ul>
  );
};
