import { isValid, isAfter, getYear, parse } from 'date-fns';

export const validateBirthDate = value => {
  const currentDate = new Date();
  const date = parse(value, 'dd/MM/yyyy', new Date());
  if (!isValid(date)) {
    return false;
  }

  if (isAfter(date, currentDate)) {
    return false;
  }

  if (getYear(date) < 1901) {
    return false;
  }

  return true;
};
