import { Input } from '../index';

export const InputEmail = ({
  register,
  errors,
  label,
  value,
  placeholder = ''
}) => {
  return (
    <div>
      <label htmlFor="email" className="sr-only text-primary-medium">
        Email address
      </label>

      <Input
        id="email"
        label={label}
        placeholder={placeholder}
        register={register}
        value={value}
        validations={{
          required: 'Este é um campo obrigatório.',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Digite um e-mail válido'
          }
        }}
        errors={errors}
      />
    </div>
  );
};
