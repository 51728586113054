/**
 * It rounds the decimal part of a number to its lower decimal value,
 *  in Brazilian portuguese format.
 *
 * `(e.g: input: 3.29 => 3.2)`
 * @param {number | string} num The input number to round its decimal.
 * @returns {string} The string number with its rounded decimal.
 */
export const decimalRoundDown = (num: string | number): string => {
  const numAsString = String(num).replace(',', '.');

  if (!/\d\.\d/g.test(String(num))) {
    return `${numAsString},0`;
  }

  const [integer, decimal] = numAsString.split('.');

  return `${integer}.${decimal[0]}`;
};
