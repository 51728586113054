import { InputHTMLAttributes } from 'react';
import { QuantityButton } from './QuantityButton';
import Subtract from '../../../../public/assets/icons/subtract.svg';
import Sum from '../../../../public/assets/icons/sum.svg';

interface QuantityProps extends InputHTMLAttributes<HTMLInputElement> {
  handleAdd?: () => void;
  handleMinus?: () => void;
  disabled: boolean;
}

const styles = `
  flex
  max-w-[56px]
  h-[32px]
  px-1
  p-0
  border
  rounded
  border-gray-light
  bg-transparent
  text-gray-dark
  outline-[0]
  text-center
  font-bold
`;

export const Quantity = ({
  handleMinus,
  handleAdd,
  disabled,
  ...rest
}: QuantityProps) => {
  return (
    <div className="flex gap-2">
      <QuantityButton onClick={handleMinus} disabled={disabled}>
        <Subtract />
      </QuantityButton>
      <div className="block">
        <input {...rest} className={styles} />
      </div>
      <QuantityButton onClick={handleAdd}>
        <Sum />
      </QuantityButton>
    </div>
  );
};
