import React from 'react';

import ImageFlashSale from '@assets/icons/promo-flash.png';
import Image from 'next/image';
import Link from 'next/link';

export const PromotionalCountdown = () => {
  return (
    <Link
      href={`/ofertas-relampago/c`}
      id="bnl-oferta-relampago"
      className="flex w-full items-center cursor-pointer bnl-oferta-relampago min-h-12"
    >
      <div className="no-underline flex w-full justify-center items-center bg-tertiary-dark px-2 dark:bg-primary-darkest min-h-12">
        <strong className="flex items-center justify-center text-xs md:text-sm text-white uppercase font-bold min-h-12">
          <Image src={ImageFlashSale} alt="" />
        </strong>
      </div>
    </Link>
  );
};
