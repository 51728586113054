import { Input } from '@components/common';
import Eye from '@assets/icons/eye.svg';
import EyeClosed from '@assets/icons/eye-closed.svg';
import { useState } from 'react';

export const InputPassword = ({
  errors,
  register,
  validations,
  label,
  id,
  placeholder = ''
}) => {
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <div className="relative">
      <Input
        id={id ? id : 'password'}
        placeholder={placeholder}
        type={passwordShown ? 'text' : 'password'}
        label={label ? label : 'Senha'}
        register={register}
        validations={validations}
        errors={errors}
      />

      <div className="absolute right-5 flex top-9">
        {passwordShown ? (
          <button type="button" onClick={togglePassword}>
            <EyeClosed width={28} className="keep-color text-gray-dark" />
          </button>
        ) : (
          <button type="button" onClick={togglePassword}>
            <Eye width={28} className="keep-color text-gray-dark" />
          </button>
        )}
      </div>
    </div>
  );
};
