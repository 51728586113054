import { useRecurrence } from '@hooks';

const SubscriptionDisclaimerText = ({ color }) => {
  const {
    recurrenceConfig: { cupom_discount }
  } = useRecurrence();
  return (
    <>
      <p className={`text-sm mt-2 ${color}`}>
        {cupom_discount}% OFF adicional e frete grátis
      </p>
      <p className={`text-sm mt-2 ${color}`}>
        Pedido automático com envio mensal. Cancelamento grátis quando quiser.
      </p>
    </>
  );
};

export default SubscriptionDisclaimerText;
